import React, { useState } from 'react'
import cs from 'classnames'

import UpdateClientModal from './UpdateClientModal'

import {
  useSearchClientsQuery,
  useGetNutritionistsQuery,
} from '@api/adminApiSlice'

import { useAuth } from '@utils/hooks'
import { format } from '@utils/date'
import { getName } from '@utils/user'

import styles from './Admin.module.scss'

const Admin = () => {
  const { user } = useAuth()
  const [input, set_input] = useState('')
  const [search, set_search] = useState('')
  const [selectedClient, set_selectedClient] = useState()

  const { data: clients } = useSearchClientsQuery(
    { search },
    { skip: !search || !user.admin }
  )
  const { data: nutritionists } = useGetNutritionistsQuery(null, {
    skip: !user.admin,
  })

  const handleSearch = (e) => {
    e.preventDefault()
    set_search(input)
  }

  const getNutritionistName = (nutritionist) => {
    if (!nutritionist) return '-'
    const { firstName, lastName } = nutritionist
    return `${firstName} ${lastName}`
  }

  if (!user.admin) return null

  return (
    <div className={styles.Admin} data-testid="main-page-settings">
      <form onSubmit={handleSearch}>
        <input
          className={styles.search}
          placeholder="Search clients"
          value={input}
          onChange={(e) => set_input(e.target.value)}
          autoComplete="off"
        />
      </form>

      {search && clients && nutritionists && (
        <div className={styles.results}>
          <div className={cs(styles.header, styles.row)}>
            <div className={styles.name}>Name</div>
            <div className={styles.role}>Status</div>
            <div className={styles.nutritionist}>Primary Nutritionist</div>
            <div className={styles.nutritionist}>Secondary Nutritionist</div>
            <div className={styles.active}>Last Online</div>
            <div className={styles.email}>Email</div>
          </div>

          <div className={styles.clients}>
            {clients.length ? (
              clients.map((client) => {
                const {
                  id,
                  email,
                  nutritionist,
                  nutritionist2,
                  role,
                  lastActive,
                } = client
                return (
                  <div
                    className={styles.row}
                    onClick={() => set_selectedClient(client)}
                    key={id}
                  >
                    <div className={styles.name}>{getName(client)}</div>
                    <div className={styles.role}>
                      {role.id === 1 ? 'Active' : 'Inactive'}
                    </div>
                    <div className={styles.nutritionist}>
                      {getNutritionistName(nutritionist)}
                    </div>
                    <div className={styles.nutritionist}>
                      {getNutritionistName(nutritionist2)}
                    </div>
                    <div className={styles.active}>
                      {lastActive
                        ? format(new Date(lastActive), 'MM-dd-yyyy')
                        : '-'}
                    </div>
                    <div className={styles.email}>{email}</div>
                  </div>
                )
              })
            ) : (
              <div className={styles.noResults}>No clients found</div>
            )}
          </div>
        </div>
      )}

      {selectedClient && (
        <UpdateClientModal
          client={selectedClient}
          nutritionists={nutritionists}
          onClose={() => set_selectedClient(null)}
        />
      )}
    </div>
  )
}

export default Admin

import cs from 'classnames'

import icon_expand from '@assets/icon_expand.svg'

import styles from './DropdownButton.module.scss'

const DropdownButton = ({ className, children, ...rest }) => (
  <button className={cs(className, styles.DropdownButton)} {...rest}>
    {children}
    <img src={icon_expand} alt="open dropdown" />
  </button>
)

export default DropdownButton

import { createSlice } from '@reduxjs/toolkit'

// import { today } from '@utils/date'

const initialState = {
  message: null,
}

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    messageSet(state, action) {
      state.message = action.payload
    },
  },
})

export const { messageSet } = messageSlice.actions

export default messageSlice.reducer

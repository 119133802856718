import { Fragment } from 'react'
import cs from 'classnames'

import { Spinner } from '@comp/Loader/Loader'

import styles from './Button.module.scss'

const Button = ({
  className,
  color = 'green',
  border,
  children,
  full,
  isLoading,
  disabled,
  ...rest
}) => (
  <button
    className={cs(
      className,
      styles.Button,
      styles[color],
      full && styles.full,
      border && styles.border,
      disabled && styles.disabled
    )}
    disabled={isLoading || disabled}
    {...rest}
  >
    {children}

    {isLoading && (
      <Fragment>
        <Spinner className={styles.spinner} />
        <div
          className={cs(styles.loadingOverlay, isLoading && styles.isLoading)}
        />
      </Fragment>
    )}
  </button>
)

export default Button

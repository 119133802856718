import React from 'react'
import cs from 'classnames'

import Popup from '@comp/Popup/Popup'

import { format } from '@utils/date'
import { formatTargetLabel } from '@utils/meals'

import iconCheck from '@assets/icon-check-circle-white.svg'
import iconError from '@assets/icon-attention-circle.svg'

import styles from './TargetsPopup.module.scss'

const TargetsPopup = ({ date, deltas, totals, targets, index, onClose }) => (
  <Popup
    className={cs(styles.TargetsPopup, index > 3 && styles.left)}
    onClose={onClose}
  >
    <div className={styles.title}>Food Log Trackers</div>
    <div className={styles.date}>{format(date, 'eeee, LLL d, yyyy')}</div>
    <div className={styles.targets}>
      {deltas.map((delta) => {
        const { name } = delta
        const value = totals[name]
        const target = targets[name]
        return <Target {...delta} {...target} value={value} key={delta.name} />
      })}
    </div>
  </Popup>
)

const Target = ({ name, color, abbr, value, delta, min, max }) => {
  const labelArr = [value]
  if (delta !== null) {
    labelArr.push('/')
    labelArr.push(formatTargetLabel({ min, max }))
  }
  const label = labelArr.join(' ')

  let status
  let background
  if (delta === 0) {
    status = <img src={iconCheck} alt="target met" />
    background = color
  } else if (delta > 0) {
    status = <img src={iconError} alt="over target" />
    background = '#88939D'
  } else {
    status = null
  }

  return (
    <div
      className={cs(styles.Target, (delta === 0 || delta > 0) && styles.white)}
      style={{ background }}
      key={name}
    >
      <div className={styles.header}>
        <div className={styles.name}>{abbr}</div>
        <div className={styles.status}>{status}</div>
      </div>
      {status === null &&
        (delta === null ? (
          <div className={styles.noTarget} />
        ) : (
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{ background: color, width: `${(value / min) * 100}%` }}
            />
          </div>
        ))}
      <div className={styles.label}>{label}</div>
    </div>
  )
}

export default TargetsPopup

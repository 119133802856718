import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import ResourcesModal from './ResourcesModal'
import { Section, ListItem } from '../Components'

import {
  useGetClientResourcesQuery,
  useGetClientResourceCollectionsQuery,
  useSetClientResourcesMutation,
  useSetClientResourceCollectionsMutation,
} from '@api/resourcesApiSlice'

import { selectCurrentClient } from '@state/clientSlice'

import { getResourceName } from '@utils/resources'

const Resources = () => {
  const [showResourcesModal, set_showResourcesModal] = useState()
  const client = useSelector(selectCurrentClient)
  const { data: resources } = useGetClientResourcesQuery(
    { clientId: client?.id },
    { skip: !client }
  )
  const { data: resourceCollections } = useGetClientResourceCollectionsQuery(
    { clientId: client?.id },
    { skip: !client }
  )
  const [updateResources] = useSetClientResourcesMutation()
  const [updateCollections] = useSetClientResourceCollectionsMutation()

  const handleSave = async ({ selectedResources, selectedCollections }) => {
    await Promise.all([
      updateResources({
        clientId: client.id,
        resources: selectedResources.map(({ id }) => id),
      }),
      updateCollections({
        clientId: client.id,
        resourceCollections: selectedCollections.map(({ id }) => id),
      }),
    ])
    set_showResourcesModal(false)
  }

  if (!resources || !resourceCollections) return null

  return (
    <Section
      title="Resources"
      onEdit={() => set_showResourcesModal(true)}
      data-testid="client-profile-resources"
    >
      {resourceCollections.map(({ id, name }) => (
        <ListItem label={name} category="Collection" key={id} />
      ))}

      {resources.map(({ id, name, internalName, resource_collection }) => (
        <ListItem
          label={getResourceName({ name, internalName })}
          category="Individual Resource"
          key={id}
        />
      ))}

      {!resourceCollections.length && !resources.length && (
        <ListItem category="No resources assigned" />
      )}

      {showResourcesModal && (
        <ResourcesModal
          assignedResources={resources}
          assignedCollections={resourceCollections}
          onClose={handleSave}
        />
      )}
    </Section>
  )
}

export default Resources

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addWeeks, subWeeks } from 'date-fns'

import CalendarPopup from '@comp/CalendarPopup/CalendarPopup'
import DropdownButton from '@comp/DropdownButton/DropdownButton'

import { useGetClientMealDatesQuery } from '@api/nutritionistApiSlice'

import {
  selectCurrentClient,
  selectWeekStart,
  weekStartSet,
  selectWeekEnd,
} from '@state/clientSlice'

import {
  format,
  utcToZonedTime,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  dateFormat,
  isSameDate,
} from '@utils/date'

import iconPrev from '@assets/icon-prev.svg'
import iconNext from '@assets/icon-next.svg'

import styles from './WeekSelector.module.scss'

const WeekSelector = () => {
  const weekStart = useSelector(selectWeekStart)
  const weekEnd = useSelector(selectWeekEnd)
  const client = useSelector(selectCurrentClient)

  const [showCalendar, set_showCalendar] = useState(false)
  const [monthStart, set_monthStart] = useState(startOfMonth(new Date()))

  const dispatch = useDispatch()
  const setWeekStart = (date) => dispatch(weekStartSet(date))

  const calStart = format(startOfWeek(monthStart), dateFormat)
  const calEnd = format(endOfWeek(endOfMonth(monthStart)), dateFormat)

  const { data: mealDates } = useGetClientMealDatesQuery(
    {
      clientId: client?.id,
      start: calStart,
      end: calEnd,
    },
    { skip: !client }
  )

  const mealDatesSet = new Set(
    mealDates?.map(({ datetime, timezone }) =>
      format(utcToZonedTime(new Date(datetime), timezone), dateFormat, {
        timeZone: timezone,
      })
    )
  )

  useEffect(() => {
    if (showCalendar) set_monthStart(startOfMonth(weekStart))
  }, [showCalendar, weekStart, set_monthStart])

  const getTileClass = ({ date }) => {
    if (isSameDate(date, weekStart)) return styles.weekStart
    if (isSameDate(date, weekEnd)) return styles.weekEnd
    if (date > weekStart && date < weekEnd) return styles.weekDay
  }

  let weekStartStr
  let weekEndStr
  if (weekStart.getYear() !== weekEnd.getYear()) {
    weekStartStr = format(weekStart, 'MMMM d, yyyy')
    weekEndStr = format(weekEnd, 'MMMM d, yyyy')
  } else if (weekStart.getMonth() !== weekEnd.getMonth()) {
    weekStartStr = format(weekStart, 'MMMM d')
    weekEndStr = format(weekEnd, 'MMMM d, yyyy')
  } else {
    weekStartStr = format(weekStart, 'MMMM d')
    weekEndStr = format(weekEnd, 'd, yyyy')
  }

  return (
    <div className={styles.WeekSelector}>
      <DropdownButton
        className={styles.weekButton}
        onClick={() => set_showCalendar(!showCalendar)}
        data-testid="foodlog-week-selector"
      >
        {weekStartStr} - {weekEndStr}
      </DropdownButton>
      <div className={styles.scrollButtons}>
        <button onClick={() => setWeekStart(subWeeks(weekStart, 1))}>
          <img src={iconPrev} alt="previous week" />
        </button>

        <button onClick={() => setWeekStart(startOfWeek(new Date()))}>
          This Week
        </button>

        <button onClick={() => setWeekStart(addWeeks(weekStart, 1))}>
          <img src={iconNext} alt="next week" />
        </button>
      </div>

      {showCalendar && (
        <CalendarPopup
          className={styles.calendar}
          tileClassName={getTileClass}
          date={weekStart}
          setDate={(date) => {
            setWeekStart(startOfWeek(date))
            set_showCalendar(false)
          }}
          onClose={() => set_showCalendar(false)}
          markedDates={mealDatesSet}
          onMonthChange={set_monthStart}
          data-testid="foodlog-calendar"
        />
      )}
    </div>
  )
}

export default WeekSelector

import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  Form,
  FormTitle,
  FormInputGroup,
  FormButton,
  FormLink,
  FormError,
} from '@comp/Form/Form'
import Loader from '@comp/Loader/Loader'

import { useGetUserQuery, useLoginMutation } from '@api/authApiSlice'

import { getLocally, setLocally } from '@utils/storage'
import { userRoles } from '@utils/user'
import { handleError, extractErrorMessage } from '@utils/errors'
import { useAuth } from '@utils/hooks'
import { tokenRestored } from '@state/authSlice'

import iconOpn from '@assets/icon-opn-large.svg'

import styles from './Account.module.scss'

const Login = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { token } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const { data: user, isError: isErrorUser } = useGetUserQuery(token, {
    skip: !token,
  })
  const [login, { isLoading: isLoadingLogin }] = useLoginMutation()

  const from = location.state?.from?.pathname || '/'

  const invalidateToken = useCallback(() => {
    setLocally('userToken', null)
    dispatch(tokenRestored({ token: null }))
  }, [dispatch])

  useEffect(() => {
    const token = getLocally('userToken')
    if (token) {
      dispatch(tokenRestored({ token }))
    }
  }, [dispatch])

  useEffect(() => {
    if (user?.id) {
      if (userRoles.includes(user.role.id)) {
        navigate(from)
      } else {
        invalidateToken()
        setError('You are not authorized')
      }
    }
  }, [user?.id, user?.role, navigate, from, invalidateToken])

  useEffect(() => {
    if (isErrorUser) invalidateToken()
  }, [isErrorUser, invalidateToken])

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const { jwt } = await login({ email, password }).unwrap()
      setLocally('userToken', jwt)
      navigate('/')
    } catch (error) {
      handleError(error)
      setError(extractErrorMessage(error))
    }
  }

  if (token) return <Loader />

  return (
    <div className={styles.Account} data-testid="login">
      <img className={styles.icon} src={iconOpn} alt="onpoint nutrition logo" />
      <Form className={styles.form} onSubmit={onSubmit}>
        <FormTitle>Nutrition Specialist Dashboard</FormTitle>
        <FormInputGroup
          id="email"
          type="email"
          label="Email Address"
          value={email}
          onChange={setEmail}
          required
          data-testid="email"
        />
        <FormInputGroup
          className={styles.password}
          id="password"
          type="password"
          label="Password"
          value={password}
          onChange={setPassword}
          required
          data-testid="password"
        />
        <FormButton
          label="Log In"
          type="submit"
          onClick={onSubmit}
          isLoading={isLoadingLogin}
          data-testid="submit"
        />
        {error && <FormError data-testid="error">{error}</FormError>}
        <div className={styles.linkWrapper}>
          <FormLink
            className={styles.link}
            to="/forgot-password"
            label="Forgot password?"
          />
        </div>
      </Form>
    </div>
  )
}

export default Login

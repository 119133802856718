import React, { useState } from 'react'
import cs from 'classnames'

import styles from './Accordion.module.scss'

import icon_expand from '@assets/icon_expand.svg'

const Accordion = ({
  className,
  initiallyOpen = true,
  title,
  children,
  disabled,
}) => {
  const [isOpen, set_isOpen] = useState(initiallyOpen)

  return (
    <div
      className={cs(className, styles.Accordion, disabled && styles.disabled)}
    >
      <div className={styles.header} onClick={() => set_isOpen(!isOpen)}>
        <div className={styles.arrowContainer}>
          <img
            className={cs(styles.arrow, isOpen && !disabled && styles.open)}
            src={icon_expand}
            alt="expand"
          />
        </div>
        {title}
      </div>
      {!disabled && isOpen && <div className={styles.children}>{children}</div>}
    </div>
  )
}

export default Accordion

import React from 'react'

import styles from './ResourceCard.module.scss'

import { getResourceName } from '@utils/resources'

const ResourceCard = ({ resource, onSelect }) => {
  const { coverImage, name, internalName, shortDescription } = resource

  return (
    <div
      className={styles.ResourceCard}
      onClick={onSelect}
      data-testid="resource-card"
    >
      <img className={styles.coverImage} src={coverImage.url} alt={name} />
      <div className={styles.info}>
        <div className={styles.name}>
          {getResourceName({ name, internalName })}
        </div>
        <div className={styles.description}>{shortDescription}</div>
      </div>
    </div>
  )
}

export default ResourceCard

import { useState, useCallback } from 'react'
import cs from 'classnames'

import styles from './ScrollList.module.scss'

const ScrollList = ({
  className,
  children,
  scrollbarMargin = 10,
  elHeight,
}) => {
  const containerRef = useCallback((node) => set_container(node), [])
  const contentRef = useCallback((node) => set_content(node), [])

  const [container, set_container] = useState()
  const [content, set_content] = useState()
  const [sbTop, setSbTop] = useState(0)

  const containerHeight = container?.clientHeight - scrollbarMargin / 2
  const contentHeight = content?.clientHeight

  let sbHeight
  if (!containerHeight || !contentHeight) sbHeight = 0
  else if (containerHeight < 0 || containerHeight === contentHeight)
    sbHeight = 0
  else {
    sbHeight = (containerHeight / contentHeight) * containerHeight
  }

  const handleScroll = (e) => {
    setSbTop((e.target.scrollTop / contentHeight) * containerHeight)
  }

  return (
    <div className={cs(className, styles.ScrollList)} ref={containerRef}>
      <div className={styles.contentContainer} onScroll={handleScroll}>
        <div className={styles.content} ref={contentRef}>
          {children}
        </div>
      </div>
      <div className={styles.scrollOverlay}>
        <div
          className={styles.scrollbar}
          style={{
            height: sbHeight,
            top: sbTop + scrollbarMargin / 2,
          }}
        />
      </div>
    </div>
  )
}

export default ScrollList

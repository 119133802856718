import { useState, useEffect } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ClientSearch from './ClientSearch'
import { Targets } from '@pages/Profile/Trackers/Trackers'
import Popup from '@comp/Popup/Popup'
import HeaderButton from '@comp/HeaderButton/HeaderButton'

import { selectCurrentClient } from '@state/clientSlice'

import { useAuth } from '@utils/hooks'
import { chatClient } from '@utils/chat'
import { formatTargetsProfile } from '@utils/meals'

import { useGetClientTargetsQuery } from '@api/nutritionistApiSlice'

import styles from './Client.module.scss'

const Client = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const client = useSelector(selectCurrentClient)
  const { data: targetProfile } = useGetClientTargetsQuery(
    { clientId: client?.id },
    { skip: !client }
  )

  const targets = formatTargetsProfile(targetProfile)

  const [showCurrentTargets, set_showCurrentTargets] = useState(false)
  const [channel, set_channel] = useState()
  const [unread, set_unread] = useState()

  useEffect(() => {
    if (!client) return

    const getChannel = async (client) => {
      const filter = {
        type: 'messaging',
        members: { $eq: [`${client.id}`, `${user.id}`] },
      }
      const channels = await chatClient.queryChannels(filter)

      const channel = channels[0]
      set_channel(channel)
      set_unread(channel?.state.unreadCount || null)
    }

    getChannel(client)
  }, [client, user])

  useEffect(() => {
    if (!channel) return
    const listener = channel.on((event) => {
      if (event.unread_count !== undefined) set_unread(event.unread_count)
    })

    return () => listener.unsubscribe()
  }, [channel])

  return (
    <div className={styles.Client} data-testid="client-page">
      <div className={styles.header}>
        <div className={styles.left}>
          <ClientSearch />
        </div>

        <div className={styles.center}>
          <nav className={styles.tabnav}>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNav : styles.navLink
              }
              to={`foodlog`}
              data-testid="client-nav-foodlog"
            >
              Food Log
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNav : styles.navLink
              }
              to={`profile`}
              data-testid="client-nav-profile"
            >
              Profile
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNav : styles.navLink
              }
              to={`analytics`}
              data-testid="client-nav-analytics"
            >
              Analytics
            </NavLink>
          </nav>
        </div>
        <div className={styles.right}>
          <div className={styles.currentTargets}>
            <HeaderButton onClick={() => set_showCurrentTargets(true)}>
              Current Targets
            </HeaderButton>

            {showCurrentTargets && (
              <Popup
                className={styles.targetsPopup}
                onClose={() => set_showCurrentTargets(false)}
              >
                <Targets targets={targets} className={styles.targets} />
              </Popup>
            )}
          </div>
          <div className={styles.chat}>
            <HeaderButton
              onClick={() => {
                navigate('/chat', {
                  state: { client: { ...client, channelId: channel.id } },
                })
              }}
            >
              Chat
            </HeaderButton>
            {unread ? <div className={styles.unread} /> : null}
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Client

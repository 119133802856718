import { createSlice } from '@reduxjs/toolkit'

import { today } from '@utils/date'

const initialState = {
  date: today(),
}

const dateSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    dateSet(state, action) {
      state.date = action.payload.date
    },
  },
})

export const { dateSet } = dateSlice.actions

export default dateSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedCollection: null,
}

const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    collectionSelected(state, { payload }) {
      state.selectedCollection = payload
    },
  },
})

export const { collectionSelected } = resourceSlice.actions

export default resourceSlice.reducer

export const collectionSelector = (state) => state.resource.selectedCollection

import React, { useState } from 'react'
import cs from 'classnames'
import { Link } from 'react-router-dom'

import { CreateGroupDialogue } from './GroupDialogues'

import icon_plus from '@assets/icon_plus.svg'

import styles from './GroupsList.module.scss'

const GroupsList = ({ clientGroups, selectedGroup }) => {
  const [showCreateDialogue, set_showCreateDialogue] = useState()

  return (
    <div className={styles.GroupsList} data-testid="groups-list">
      <div className={styles.section}>
        <ListItem label="All Clients" selected={!selectedGroup} />
      </div>

      <div className={styles.section}>
        <div className={styles.header}>
          Your Groups
          <img
            className={styles.addGroup}
            src={icon_plus}
            onClick={() => set_showCreateDialogue(true)}
            alt="add group"
          />
        </div>
        {clientGroups &&
          clientGroups.map((group) => (
            <ListItem
              label={group.name}
              selected={selectedGroup && selectedGroup.id === group.id}
              groupId={group.id}
              key={group.id}
            />
          ))}
      </div>

      {showCreateDialogue && (
        <CreateGroupDialogue onClose={() => set_showCreateDialogue(false)} />
      )}
    </div>
  )
}

const ListItem = ({ label, selected, groupId }) => (
  <Link
    className={cs(styles.ListItem, selected && styles.selected)}
    to={groupId ? `/groups/${groupId}` : `/groups/`}
  >
    {label}
  </Link>
)

export default GroupsList

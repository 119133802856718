import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'redux-first-history'

import Modal from '@comp/Modal/Modal'
import Card from '@comp/Card/Card'
import Button from '@comp/Button/Button'
import { FormInputGroup } from '@comp/Form/Form'

import { groupSelector, groupSelected } from '@state/groupSlice'

import {
  useCreateClientGroupMutation,
  useUpdateClientGroupMutation,
  useDeleteClientGroupMutation,
} from '@api/groupsApiSlice'

import styles from './GroupDialogues.module.scss'

export const CreateGroupDialogue = ({ onClose }) => {
  const dispatch = useDispatch()
  const [groupName, set_groupName] = useState('')

  const [createGroup] = useCreateClientGroupMutation()

  const handleSave = async (e) => {
    e.preventDefault()
    if (!groupName) return

    const { data: created } = await createGroup({
      name: groupName,
    })
    dispatch(groupSelected(created))
    onClose()
    dispatch(push(`/groups/${created.id}`))
  }

  return (
    <Modal onClose={onClose}>
      <Card className={styles.Dialogue} title="New Group" onClose={onClose}>
        <form onSubmit={handleSave}>
          <FormInputGroup
            className={styles.nameInput}
            value={groupName}
            onChange={set_groupName}
            placeholder="Enter group name"
          />
          <Button full disabled={!groupName} onClick={handleSave}>
            Save
          </Button>
        </form>
      </Card>
    </Modal>
  )
}

export const UpdateGroupDialogue = ({ onClose }) => {
  const dispatch = useDispatch()
  const selectedGroup = useSelector(groupSelector)
  const [groupName, set_groupName] = useState(
    selectedGroup ? selectedGroup.name : ''
  )
  const [showDeleteDialogue, set_showDeleteDialogue] = useState()

  const [updateGroup] = useUpdateClientGroupMutation()
  const [deleteGroup] = useDeleteClientGroupMutation()

  const handleSave = async (e) => {
    e.preventDefault()
    if (!groupName) return

    const { data: updated } = await updateGroup({
      ...selectedGroup,
      clients: selectedGroup.clients.map(({ id }) => id),
      name: groupName,
    })
    dispatch(groupSelected(updated))
    onClose()
  }

  const handleDelete = async () => {
    await deleteGroup({ id: selectedGroup.id })
    dispatch(groupSelected(null))
    onClose()
    dispatch(push('/groups'))
  }

  if (!selectedGroup) return null

  return (
    <Modal onClose={onClose}>
      {showDeleteDialogue ? (
        <Card
          className={styles.Dialogue}
          title="Delete Group"
          onClose={onClose}
        >
          <div className={styles.deleteInfo}>
            Are you sure you want to delete{' '}
            <span className={styles.name}>{selectedGroup.name}</span>? This
            cannot be undone.
          </div>
          <div className={styles.buttons}>
            <Button full color="white" border onClick={onClose}>
              Cancel
            </Button>
            <Button full color="red" onClick={handleDelete}>
              Delete Group
            </Button>
          </div>
        </Card>
      ) : (
        <Card className={styles.Dialogue} title="Edit Group" onClose={onClose}>
          <form onSubmit={handleSave}>
            <FormInputGroup
              className={styles.nameInput}
              value={groupName}
              onChange={set_groupName}
              placeholder="Enter group name"
            />
            <Button full onClick={handleSave} disabled={!groupName}>
              Save
            </Button>
          </form>

          <div className={styles.deleteLink}>
            <span onClick={() => set_showDeleteDialogue(true)}>
              Delete Group
            </span>
          </div>
        </Card>
      )}
    </Modal>
  )
}

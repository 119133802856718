import { useEffect } from 'react'
import WebFont from 'webfontloader'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HistoryRouter as Router } from 'redux-first-history/rr6'

import Login from '@pages/Account/Login'
import ForgotPassword from '@pages/Account/ForgotPassword'
import ForgotPasswordConfirm from '@pages/Account/ForgotPasswordConfirm'
import ResetPassword from '@pages/Account/ResetPassword'
import AccountError from '@pages/Account/AccountError'
import Main from '@pages/Main/Main'
import Chat from '@pages/Chat/Chat'
import Client from '@pages/Client/Client'
import Resources from '@pages/Resources/Resources'
import Groups from '@pages/Groups/Groups'
import Admin from '@pages/Admin/Admin'
import Foodlog from '@pages/Foodlog/Foodlog'
import Profile from '@pages/Profile/Profile'
import Analytics from '@pages/Analytics/Analytics'

import RequireAuth from '@comp/RequireAuth/RequireAuth'

import { store, history } from '@state/store'

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:400,500,600,800', 'Work Sans'],
      },
    })
  }, [])

  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password-confirm"
            element={<ForgotPasswordConfirm />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/account-error" element={<AccountError />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <Main />
              </RequireAuth>
            }
          >
            <Route index element={<Navigate to="chat" />} />
            <Route path="chat" element={<Chat />} />
            <Route path="client" element={<Client />} />
            <Route path="client/:clientId" element={<Client />}>
              <Route index element={<Navigate to="foodlog" />} />
              <Route path="foodlog" element={<Foodlog />} />
              <Route path="profile" element={<Profile />} />
              <Route path="analytics" element={<Analytics />} />
            </Route>
            <Route path="groups" element={<Groups />}>
              <Route path=":groupId" element={<Groups />} />
            </Route>
            <Route path="resources" element={<Resources />}>
              <Route path=":collectionId" element={<Resources />} />
            </Route>
            <Route path="admin" element={<Admin />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </Provider>
  )
}

export default App

import React, { useState } from 'react'
import { isAfter } from 'date-fns'

import HeaderButton from '@comp/HeaderButton/HeaderButton'
import Button from '@comp/Button/Button'

import ScheduleModalMass from './ScheduleModalMass'
import ScheduledMessagesList from './ScheduledMessagesList'
import PastMassMessage from './PastMassMessage'

import { useGetScheduledMessagesQuery } from '@api/messagesApiSlice'

import styles from './MassMessages.module.scss'

const MassMessages = () => {
  const [showScheduleModal, set_showScheduleModal] = useState()
  const [showScheduledMessages, set_showScheduledMessages] = useState()

  const { data: messages } = useGetScheduledMessagesQuery()

  const activeMessages = messages
    ? messages.filter(
        ({ direct, datetime, clients }) =>
          !direct && isAfter(new Date(datetime), new Date())
      )
    : []

  const pastMessages = messages
    ? messages.filter(
        ({ direct, datetime, clients }) =>
          !direct && !isAfter(new Date(datetime), new Date())
      )
    : []

  const numActiveMessages = activeMessages.length

  return (
    <div className={styles.MassMessages}>
      <div className={styles.header}>
        <div className={styles.name}>Mass Messages</div>
        <HeaderButton onClick={() => set_showScheduledMessages(true)}>
          Scheduled Messages
          {numActiveMessages ? (
            <span className={styles.numActiveMessages}>
              ({numActiveMessages})
            </span>
          ) : null}
          {showScheduledMessages && (
            <ScheduledMessagesList
              messages={activeMessages}
              onClose={() => set_showScheduledMessages(false)}
              openScheduleModal={() => set_showScheduleModal(true)}
              closeOnClickOutside={!showScheduleModal}
            />
          )}
        </HeaderButton>
      </div>

      <div className={styles.messages}>
        {pastMessages.map((message) => (
          <PastMassMessage {...message} key={message.id} />
        ))}
      </div>

      <div className={styles.footer}>
        <Button onClick={() => set_showScheduleModal(true)}>New Message</Button>
      </div>

      {showScheduleModal && (
        <ScheduleModalMass onClose={() => set_showScheduleModal(false)} />
      )}
    </div>
  )
}

export default MassMessages

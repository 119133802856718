import cs from 'classnames'

import styles from './HeaderButton.module.scss'

const HeaderButton = ({ className, children, ...rest }) => (
  <div className={cs(styles.HeaderButton, className)} {...rest}>
    {children}
  </div>
)

export default HeaderButton

import { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { format } from 'date-fns'

import styles from './WeightChart.module.scss'

const WeightChart = ({ interval, weights }) => {
  const containerRef = useRef()
  const chartRef = useRef()

  const margin = { top: 0, right: 25, bottom: 0, left: 25 }
  const height = 200 - margin.top - margin.bottom

  useEffect(() => {
    const width = containerRef.current.clientWidth - margin.left - margin.right
    const svg = d3.select(chartRef.current)
    d3.selectAll('svg#weight #plot').remove()

    // set the ranges
    const timeScale = d3.scaleBand().range([0, width]).padding(0.15)
    const weightScale = d3.scaleLinear().range([height, 0])

    // Scale the range of the history
    timeScale.domain(weights.map((w, index) => index))
    const minValue =
      Math.abs(
        d3.min(
          weights.filter(({ value }) => value),
          ({ value }) => value
        )
      ) || 0
    const maxValue = Math.abs(d3.max(weights, ({ value }) => value))

    // console.log({ minValue, maxValue })
    weightScale
      .domain([Math.max(minValue - 10, 0), maxValue + 10])
      .range([height, 0])

    const data = weights
      .map((weight, index) => ({ ...weight, index }))
      .filter(({ value }) => value)

    // Add background
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('class', styles.plot)

    const plot = svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('id', 'plot')

    // Select tooltip
    const tooltip = {
      container: d3.select(`.${styles.tooltip}`),
      date: d3.select('#analytics_weights_tooltip_date'),
      value: d3.select('#analytics_weights_tooltip_value'),
    }
    const handleMouseOver = (event, { start, end, value }) => {
      const formatDate = (date) => format(new Date(date), 'MMM d')
      const dateLabel =
        start === end
          ? formatDate(start)
          : `${formatDate(start)} - ${formatDate(end)}`
      const valueLabel = `${value} lbs`
      const offset = event.x > window.innerWidth - 200 ? 210 : 0
      tooltip.container
        .style('opacity', 1)
        .style('left', `${event.x - offset}px`)
        .style('top', `${event.y}px`)
      tooltip.date.html(dateLabel)
      tooltip.value.html(valueLabel)
    }
    const handleMouseMove = (event) => {
      const offset = event.x > window.innerWidth - 200 ? 210 : 0
      tooltip.container
        .style('left', `${event.x - offset}px`)
        .style('top', `${event.y}px`)
    }
    const handleMouseLeave = () => {
      tooltip.container
        .style('opacity', 0)
        .style('left', `-1000px`)
        .style('top', `-1000px`)
    }

    // Add bars
    const radius = 2
    plot
      .selectAll('.weight')
      .data(data)
      .enter()
      .append('path')
      .attr('class', styles.weight)
      .attr('d', (d) => {
        const x = timeScale(d.index)
        const y = weightScale(d.value)
        const path = `
        M${x}, ${height}
        L${x}, ${y + radius}
        a${radius},${radius},0 0 1 ${radius}, ${-radius}
        L${x + timeScale.bandwidth() - radius}, ${y}
        a${radius},${radius},0 0 1 ${radius}, ${radius}
        L${x + timeScale.bandwidth()}, ${height}
        Z
        `
        return path
      })
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseMove)
      .on('mouseleave', handleMouseLeave)

    // Add the x Axis
    let intervalLabel = 'Daily'
    if (interval === 'week') intervalLabel = 'Weekly'
    else if (interval === 'month') intervalLabel = 'Monthly'

    plot
      .append('text')
      .attr('transform', `translate(${width / 2}, ${height + 25})`)
      .attr('class', styles.axisLabel)
      .style('text-anchor', 'middle')
      .text(`${intervalLabel} weight`)

    plot.selectAll('.domain').remove()
    plot.selectAll('.tick line').remove()
  }, [
    weights,
    height,
    margin.bottom,
    margin.left,
    margin.right,
    margin.top,
    interval,
  ])

  return (
    <div
      className={styles.WeightChart}
      history-testid="client-page-analytics"
      ref={containerRef}
    >
      <svg
        style={{
          height: 230,
          width: '100%',
          marginRight: '0px',
          marginLeft: '0px',
        }}
        id="weight"
        ref={chartRef}
      />

      <div className={styles.tooltip}>
        <div className={styles.label}>Date</div>
        <div id="analytics_weights_tooltip_date" />
        <div className={styles.label}>Logged</div>
        <div id="analytics_weights_tooltip_value" />
      </div>
    </div>
  )
}

export default WeightChart

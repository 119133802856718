import * as Sentry from '@sentry/react'

export const extractErrorMessage = (error) => {
  return error.data?.error?.message
}

export const handleError = (error) => {
  if (error.status === 'FETCH_ERROR') {
    const err = new Error('Connection refused')
    err.name = 'CONN_ERROR'
    window.alert('Connection error. Please contact engineering.')
    Sentry.captureException(err)
  } else if (error.response) {
    throw error.response.data
  } else if (error.request) {
    console.log(error.request)
  }
}

import { createSlice } from '@reduxjs/toolkit'

import { authApi } from '@api/authApiSlice'

const initialState = {
  token: null,
  user: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    tokenRestored(state, { payload }) {
      return {
        token: payload.token,
        user: null,
      }
    },
    signedOut() {
      return {
        token: null,
        user: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.jwt
        state.user = payload.user
      }
    )

    builder.addMatcher(
      authApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload
      }
    )
  },
})

export const { tokenRestored, signedOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectUserToken = (state) => state.auth.token

import { format, dateFormat, today, timezone } from '@utils/date'

const getApi = ({ api, authApi, log, logMeta }) => {
  return {
    async searchFood(searchTerm) {
      return (await authApi()).get(`/ingredients/search?search=${searchTerm}`)
    },

    async getMealsByDate(date) {
      return (await authApi()).get(
        `/user-meals?date=${date}&timezone=${timezone}`
      )
    },

    async getMealsByDateRange(start, end) {
      return (await authApi()).get(
        `/user-meals/dates?start=${start}&end=${end}&timezone=${timezone}`
      )
    },

    async getRecentFoods() {
      return (await authApi()).get(`/users/me/foodlog/foods/recent`)
    },

    async deleteTodaysMeals() {
      return (await authApi()).delete(
        `/user-meals/date?date=${format(
          today(),
          dateFormat
        )}&timezone=${timezone}`
      )
    },
  }
}

const api = { getApi }

export default api

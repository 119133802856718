import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Form,
  FormTitle,
  FormInputGroup,
  FormButton,
  FormError,
} from '@comp/Form/Form'

import { useResetPasswordMutation } from '@api/authApiSlice'

import { validatePassword } from '@utils/user'

import iconOpn from '@assets/icon-opn-large.svg'

import styles from './Account.module.scss'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [error, setError] = useState(null)
  const [searchParams] = useSearchParams()

  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  const code = searchParams.get('code')

  const onSubmit = async (e) => {
    e.preventDefault()

    if (password !== passwordConfirmation) {
      setError('Your passwords do not match. Try again')
      return
    }

    if (
      process.env.REACT_APP_ENV !== 'development' &&
      !validatePassword(password)
    ) {
      setError(
        'Password must contain lowercase letter, uppercase letter, number, special character, and a minimum of 8 characters.'
      )
      return
    }

    try {
      await resetPassword({
        password,
        passwordConfirmation,
        code,
      }).unwrap()
      toast.success('Your password has been changed')
      navigate('/login', { state: { from: 'reset' } })
    } catch (error) {
      navigate('/account-error')
    }
  }

  return (
    <div className={styles.Account}>
      <img className={styles.icon} src={iconOpn} alt="onpoint nutrition logo" />
      <Form className={styles.form} onSubmit={onSubmit}>
        <FormTitle>Password Reset</FormTitle>
        <FormInputGroup
          id="password"
          type="password"
          label="Enter New Password"
          value={password}
          onChange={setPassword}
          required
        />
        <FormInputGroup
          id="confirm"
          type="password"
          label="Confirm Password"
          value={passwordConfirmation}
          onChange={setPasswordConfirmation}
          required
        />
        <FormButton
          label="Reset Password"
          type="submit"
          onClick={onSubmit}
          isLoading={isLoading}
        />
        {error && <FormError>{error}</FormError>}
      </Form>
    </div>
  )
}

export default ResetPassword

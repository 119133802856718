import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiHost } from '@api'

import { hashByPropertyAsArray, sortBy } from '@utils'

export const goalsApi = createApi({
  reducerPath: 'goalsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Goal', 'UserGoal'],
  endpoints: (builder) => ({
    getGoals: builder.query({
      query: () => ({
        url: `/goals`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        hashByPropertyAsArray(response, 'category'),
      providesTags: ['Goal'],
    }),
    getClientGoals: builder.query({
      query: ({ clientId }) => ({
        url: `/user-goals?client=${clientId}`,
        method: 'GET',
      }),
      transformResponse: (userGoals) => sortBy(userGoals, 'id'),
      providesTags: ['UserGoal'],
    }),
    setClientGoals: builder.mutation({
      query: ({ clientId, previousGoals, goals }) => ({
        url: `/user-goals?client=${clientId}`,
        method: 'PUT',
        body: { previousGoals, goals },
      }),
      invalidatesTags: ['UserGoal'],
    }),
  }),
})

export const {
  useGetGoalsQuery,
  useGetClientGoalsQuery,
  useSetClientGoalsMutation,
} = goalsApi

import React from 'react'

import { defaultGroups } from '@utils/meals'
import { round } from '@utils'

import styles from './StatsTable.module.scss'

const StatsTable = ({ foodlogAverages, stats }) => {
  if (!foodlogAverages || !stats) return null

  const orderedAverages = defaultGroups
    .map(({ name }) => ({
      name,
      average: foodlogAverages.averages[`${name}_avg`],
      sd: foodlogAverages.averages[`${name}_stddev`],
    }))
    .filter(({ average }) => average !== null)
    .map(({ name, average, sd }) => ({ name, average: Math.abs(average), sd }))
    .sort((a, b) => {
      if (a.average < b.average) return -1
      if (a.average > b.average) return 1
      return a.sd < b.sd ? -1 : 1
    })

  const mostHit = orderedAverages[0]?.name || 'None'
  const mostMissed = orderedAverages[orderedAverages.length - 1]?.name || 'None'

  return (
    <div className={styles.StatsTable}>
      {' '}
      <div className={styles.row}>
        <div className={styles.cell}>
          <div className={styles.label}>Most Hit Target</div>
          <div className={styles.value}>{mostHit}</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.label}>Most Missed Target</div>
          <div className={styles.value}>{mostMissed}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <div className={styles.label}>Longest Log Streak</div>
          <div className={styles.value}>{stats.streak}</div>
        </div>
        <div className={styles.cell}>
          <div className={styles.label}>OnPoint™ Days</div>
          <div className={styles.value}>{stats.onPointDays}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <div className={styles.label}>Most Frequent Food</div>
          <div className={styles.value}>
            {stats.mostFrequentFood?.name || 'None'}
          </div>
        </div>
        <div className={styles.cell}>
          <div className={styles.label}>Avg. Meals Per Day</div>
          <div className={styles.value}>
            {stats.averageMeals === null
              ? 'None'
              : round(stats.averageMeals, 1)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatsTable

import { Form, FormTitle } from '@comp/Form/Form'

import iconOpn from '@assets/icon-opn-large.svg'

import styles from './Account.module.scss'

const ForgotPasswordConfirm = () => {
  return (
    <div className={styles.Account}>
      <img className={styles.icon} src={iconOpn} alt="onpoint nutrition logo" />
      <Form className={styles.form}>
        <FormTitle>Password Reset Email Sent</FormTitle>
        <div className={styles.details}>
          We’ve sent you an email to reset your password.
          <br />
          It should arrive shortly!
        </div>
      </Form>
    </div>
  )
}

export default ForgotPasswordConfirm

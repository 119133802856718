const getApi = ({ api, log, logMeta }) => ({
  async getUser(token) {
    return api({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).get('/users/me')
  },
})

const api = { getApi }

export default api

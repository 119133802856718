import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {
  addDays,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  formatRelative,
  isBefore,
} from 'date-fns'
import enUS from 'date-fns/locale/en-US'

const dateFormat = 'yyyy-MM-dd'

const today = () => new Date()

const yesterday = () => addDays(new Date(), -1)

const isToday = (date) => isSameDate(date, today())

const isYesterday = (date) => isSameDate(date, yesterday())

const isSameDate = (date1, date2) => {
  return format(date1, dateFormat) === format(date2, dateFormat)
}

const startOfWeekMonday = (date) => startOfWeek(date, { weekStartsOn: 1 })
const endOfWeekMonday = (date) => endOfWeek(date, { weekStartsOn: 1 })

const getMonthRange = (date) => {
  return {
    start: format(startOfMonth(date), dateFormat),
    end: format(endOfMonth(date), dateFormat),
  }
}

const getDayIndex = (date, timezone) => {
  const dayIndexSundayBase = utcToZonedTime(date, timezone).getDay()
  if (dayIndexSundayBase === 0) return 6
  return dayIndexSundayBase - 1
}

const formatRelativeToday = (date, showTime) => {
  let lastWeek = 'EEEE'
  let yesterday = `'Yesterday'`
  let today = `'Today'`
  let tomorrow = `'Tomorrow'`
  let nextWeek = `'Next' EEEE`

  const monday = startOfWeekMonday(new Date())
  if (isBefore(date, monday)) lastWeek = `'Last' EEEE`

  if (showTime) {
    lastWeek += ` 'at' h:mm a`
    yesterday += ` 'at' h:mm a`
    today += ` 'at' h:mm a`
    tomorrow += ` 'at' h:mm a`
    nextWeek += ` 'at' h:mm a`
  }

  const formatRelativeLocale = {
    lastWeek,
    yesterday,
    today,
    tomorrow,
    nextWeek,
    other: 'MM/dd/yyyy',
  }

  const locale = {
    ...enUS,
    formatRelative: (token) => formatRelativeLocale[token],
  }

  return formatRelative(date, new Date(), { locale, weekStartsOn: 0 })
}

const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export {
  format,
  utcToZonedTime,
  dateFormat,
  today,
  yesterday,
  isToday,
  isYesterday,
  isSameDate,
  getMonthRange,
  startOfMonth,
  endOfMonth,
  startOfWeekMonday as startOfWeek,
  endOfWeekMonday as endOfWeek,
  getDayIndex,
  formatRelativeToday,
}

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

import React, { useState } from 'react'

import Modal from '@comp/Modal/Modal'
import SelectList, { SelectItem } from '@comp/SelectList/SelectList'
import { FormInputGroup } from '@comp/Form/Form'

import { useGetResourceCollectionsQuery } from '@api/resourcesApiSlice'

import { getResourceName } from '@utils/resources'

import icon_close from '@assets/icon_close.svg'

import styles from './ResourcesModal.module.scss'

const ResourcesModal = ({
  assignedResources,
  assignedCollections,
  onClose,
}) => {
  const { data: resourceCollections } = useGetResourceCollectionsQuery()

  const [selectedResources, set_selectedResources] = useState(assignedResources)
  const [selectedCollections, set_selectedCollections] =
    useState(assignedCollections)

  const [search, set_search] = useState('')

  const toggleCollection = (collection) => {
    if (selectedCollections.find(({ id }) => collection.id === id)) {
      set_selectedCollections(
        selectedCollections.filter(({ id }) => collection.id !== id)
      )
    } else {
      const newCollections = [...selectedCollections, collection]
      const collectionIds = newCollections.map(({ id }) => id)
      const newResources = selectedResources.filter(
        ({ resourceCollection }) =>
          !collectionIds.includes(resourceCollection.id)
      )
      set_selectedCollections(newCollections)
      set_selectedResources(newResources)
    }
  }

  const toggleResource = (resource) => {
    if (selectedResources.find(({ id }) => resource.id === id)) {
      set_selectedResources(
        selectedResources.filter(({ id }) => resource.id !== id)
      )
    } else {
      const newResource = {
        ...resource,
        resourceCollection: { id: resource.resourceCollection },
      }
      set_selectedResources([...selectedResources, newResource])
    }
  }

  const handleClose = () => {
    onClose({ selectedResources, selectedCollections })
  }

  if (!resourceCollections) return null

  const matchesSearch = ({ name, internalName }) =>
    getResourceName({ name, internalName })
      .toLowerCase()
      .includes(search.toLowerCase())

  return (
    <Modal onClose={handleClose} closeOnClickOutside={false}>
      <div
        className={styles.ResourcesModal}
        data-testid="client-profile-resources-modal"
      >
        <div className={styles.header}>
          Resources
          <button onClick={handleClose}>
            <img src={icon_close} alt="close resources modal" />
          </button>
        </div>

        <div className={styles.searchContainer}>
          <FormInputGroup
            className={styles.search}
            value={search}
            onChange={set_search}
            placeholder="Search resources"
          />
        </div>

        <div className={styles.resources}>
          <SelectList className={styles.list} title="Currently Assigned">
            {selectedCollections.map((collection) => (
              <SelectItem
                className={styles.item}
                selected={selectedCollections.find(
                  ({ id }) => id === collection.id
                )}
                onSelect={() => toggleCollection(collection)}
                key={collection.id}
              >
                {collection.name}
              </SelectItem>
            ))}
            {selectedResources.map((resource) => (
              <SelectItem
                className={styles.item}
                selected={selectedResources.find(
                  ({ id }) => id === resource.id
                )}
                onSelect={() => toggleResource(resource)}
                key={resource.id}
              >
                {getResourceName(resource)}
              </SelectItem>
            ))}
          </SelectList>

          <SelectList className={styles.list} title="Collections">
            {resourceCollections
              .filter(({ resources }) => resources.length)
              .filter(({ name }) =>
                name.toLowerCase().includes(search.toLowerCase())
              )
              .map((collection) => (
                <SelectItem
                  className={styles.item}
                  selected={selectedCollections.find(
                    ({ id }) => id === collection.id
                  )}
                  onSelect={() => toggleCollection(collection)}
                  key={collection.id}
                >
                  {collection.name}
                </SelectItem>
              ))}
          </SelectList>

          {resourceCollections.map((collection) => (
            <SelectList
              className={styles.list}
              title={collection.name}
              initiallyOpen={false}
              disabled={
                selectedCollections.find(({ id }) => id === collection.id) ||
                !collection.resources.find(matchesSearch)
              }
              key={collection.id}
            >
              {collection.resources.filter(matchesSearch).map((resource) => (
                <SelectItem
                  className={styles.item}
                  selected={selectedResources.find(
                    ({ id }) => id === resource.id
                  )}
                  onSelect={() => toggleResource(resource)}
                  key={resource.id}
                >
                  {getResourceName(resource)}
                </SelectItem>
              ))}
            </SelectList>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default ResourcesModal

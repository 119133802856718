import React, { useState } from 'react'

import Modal from '@comp/Modal/Modal'
import Card from '@comp/Card/Card'
import {
  Form,
  FormInputGroup,
  FormSelectGroup,
  FormButton,
  FormError,
} from '@comp/Form/Form'

import { useUpdateClientMutation } from '@api/adminApiSlice'

import { extractErrorMessage } from '@utils/errors'

import styles from './UpdateClientModal.module.scss'

const UpdateClientModal = ({ client, nutritionists, onClose }) => {
  const [firstName, set_firstName] = useState(client.firstName)
  const [lastName, set_lastName] = useState(client.lastName)
  const [email, set_email] = useState(client.email)
  const [nutritionist, set_nutritionist] = useState(client.nutritionist?.id)
  const [nutritionist2, set_nutritionist2] = useState(
    client.nutritionist2?.id || null
  )
  const [role, set_role] = useState(client.role.id)
  const [error, set_error] = useState('')

  const [updateClient, { isLoading }] = useUpdateClientMutation()

  const onSubmit = async (e) => {
    e.preventDefault()

    const response = await updateClient({
      userId: client.id,
      body: {
        firstName,
        lastName,
        email,
        nutritionist,
        nutritionist2,
        role,
      },
    })

    if (response.error) {
      const message = extractErrorMessage(response.error)
      console.log(message)
      set_error(message)
    } else onClose()
  }

  const nutritionistOptions = nutritionists.map(
    ({ id, firstName, lastName }) => ({
      value: id,
      text: `${firstName} ${lastName}`,
    })
  )

  return (
    <Modal
      onClose={onClose}
      className={styles.modal}
      closeOnClickOutside={false}
    >
      <Card
        className={styles.UpdateClientModal}
        title="Edit Client"
        onClose={onClose}
      >
        <Form className={styles.form} onSubmit={onSubmit}>
          <FormInputGroup
            id="firstName"
            type="firstName"
            label="First Name"
            value={firstName}
            onChange={set_firstName}
            required
            data-testid="firstName"
          />
          <FormInputGroup
            id="lastName"
            type="lastName"
            label="Last Name"
            value={lastName}
            onChange={set_lastName}
            required
            data-testid="lastName"
          />
          <FormInputGroup
            id="email"
            type="email"
            label="Email"
            value={email}
            onChange={set_email}
            required
            data-testid="email"
          />
          <FormSelectGroup
            id="nutritionist"
            label="Primary Nutritionist"
            options={nutritionistOptions}
            value={nutritionist}
            onChange={set_nutritionist}
            full
          />
          <FormSelectGroup
            id="nutritionist2"
            label="Secondary Nutritionist"
            options={[
              { value: null, text: 'None' },
              ...nutritionistOptions.filter(
                ({ value }) => value !== nutritionist
              ),
            ]}
            value={nutritionist2}
            onChange={set_nutritionist2}
            full
          />
          <FormSelectGroup
            id="role"
            label="Status"
            options={[
              { value: 1, text: 'Active' },
              { value: 7, text: 'Inactive' },
            ]}
            value={role}
            onChange={set_role}
            full
          />
          <FormButton
            label="Update"
            type="submit"
            onClick={onSubmit}
            isLoading={isLoading}
            data-testid="submit"
            disabled={!firstName || !lastName || !email || !nutritionist}
          />
          {error && <FormError data-testid="error">{error}</FormError>}
        </Form>
      </Card>
    </Modal>
  )
}

export default UpdateClientModal

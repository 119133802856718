import React, { useState, useEffect } from 'react'
import cs from 'classnames'

import icon_expand from '@assets/icon_expand.svg'

import styles from './Select.module.scss'

const Select = ({
	className,
	options,
	value,
	onChange,
	formatValue,
	full,
	label,
	...rest
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [openUp, set_openUp] = useState(true)

	return (
		<div
			className={cs(styles.Select, className, full && styles.full)}
			onClick={(e) => {
				const { y } = e.target.getBoundingClientRect()
				set_openUp(y > window.innerHeight / 2)
				setDropdownOpen(true)
			}}
			data-testid={rest['data-testid']}
		>
			{label && <div className={styles.label}>{label}</div>}
			<div className={styles.value}>
				{options.find((o) => o.value === value)?.text}
			</div>
			<img className={styles.icon} src={icon_expand} alt="open select" />
			{dropdownOpen && (
				<Dropdown
					options={options}
					onSelect={onChange}
					onClose={() => setDropdownOpen(false)}
					openUp={openUp}
				/>
			)}
		</div>
	)
}

const Dropdown = ({ options, onSelect, onClose, openUp }) => {
	useEffect(() => {
		window.addEventListener('click', onClose)

		return () => {
			window.removeEventListener('click', onClose)
		}
	}, [onClose])

	return (
		<div className={cs(styles.Dropdown, openUp && styles.openUp)} role="list">
			{options.map((option) => (
				<div
					className={styles.Option}
					key={option.value}
					onClick={() => onSelect(option.value)}
				>
					{option.text}
				</div>
			))}
		</div>
	)
}

Select.defaultProps = {
	formatValue: (value) => value,
}

export default Select

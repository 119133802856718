import React from 'react'

import Modal from '@comp/Modal/Modal'

import iconMovement from '@assets/icon-movement.svg'
import iconWater from '@assets/icon-water.svg'
import iconSleep from '@assets/icon-sleep.svg'
import iconWeight from '@assets/icon-weight.svg'
import iconNote from '@assets/icon-note.svg'

import styles from './TrackersModal.module.scss'
import { convertMinutesToString } from '@utils/math'

const TrackersModal = ({
  date,
  movements,
  water,
  sleep,
  weight,
  note,
  activeTrackers,
  onClose,
}) => {
  const activities = [
    { value: 'barre', label: 'Barre' },
    { value: 'biking', label: 'Biking' },
    { value: 'boxfit', label: 'BoxFit' },
    { value: 'boxing', label: 'Boxing' },
    { value: 'circuit_training', label: 'Circuit Training' },
    { value: 'crossfit', label: 'CrossFit' },
    { value: 'elliptical', label: 'Elliptical' },
    { value: 'hiit', label: 'HIIT' },
    { value: 'hiking', label: 'Hiking' },
    { value: 'indoor_cycling', label: 'Indoor Cycling' },
    { value: 'pilates', label: 'Pilates' },
    { value: 'rower', label: 'Rower' },
    { value: 'running', label: 'Running' },
    { value: 'strength_training', label: 'Strength Training' },
    { value: 'swimming', label: 'Swimming' },
    { value: 'walking', label: 'Walking' },
    { value: 'yoga', label: 'Yoga' },
    { value: 'other', label: 'Other' },
  ]

  const getActivityLabel = (activity) =>
    activities.find((a) => a.value === activity)?.label

  return (
    <Modal onClose={onClose}>
      <div className={styles.TrackersModal}>
        <div className={styles.title}>Additional Trackers</div>
        <div className={styles.date}>{date}</div>

        {activeTrackers.includes('movement') && (
          <div className={styles.section}>
            <div className={styles.header}>Movement</div>
            <div className={styles.entries}>
              {movements && movements.length ? (
                movements.map(({ activity, value, comment }, index) => (
                  <div className={styles.movement} key={index}>
                    <div className={styles.name}>
                      <div className={styles.activity}>
                        {getActivityLabel(activity)}
                      </div>
                      <div className={styles.for}>for</div>
                      <div className={styles.time}>{value} minutes</div>
                    </div>
                    {comment && <div className={styles.comment}>{comment}</div>}
                  </div>
                ))
              ) : (
                <div className={styles.noEntry}>
                  <img src={iconMovement} alt="movement" />
                  Not Logged
                </div>
              )}
            </div>
          </div>
        )}

        {activeTrackers.includes('water') && (
          <div className={styles.section}>
            <div className={styles.header}>Water</div>
            <div className={styles.entries}>
              {water ? (
                <div className={styles.entry}>
                  <img src={iconWater} alt="water" />
                  {water.value} oz
                </div>
              ) : (
                <div className={styles.noEntry}>
                  <img src={iconWater} alt="water" />
                  Not Logged
                </div>
              )}
            </div>
          </div>
        )}

        {activeTrackers.includes('sleep') && (
          <div className={styles.section}>
            <div className={styles.header}>Sleep</div>
            <div className={styles.entries}>
              {sleep ? (
                <div className={styles.entry}>
                  <img src={iconSleep} alt="sleep" />
                  {convertMinutesToString(sleep?.value)}
                </div>
              ) : (
                <div className={styles.noEntry}>
                  <img src={iconSleep} alt="sleep" />
                  Not Logged
                </div>
              )}
            </div>
          </div>
        )}

        {activeTrackers.includes('weight') && (
          <div className={styles.section}>
            <div className={styles.header}>Weight</div>
            <div className={styles.entries}>
              {weight ? (
                <div className={styles.entry}>
                  <img src={iconWeight} alt="weight" />
                  {weight.value} oz
                </div>
              ) : (
                <div className={styles.noEntry}>
                  <img src={iconWeight} alt="weight" />
                  Not Logged
                </div>
              )}
            </div>
          </div>
        )}

        {activeTrackers.includes('note') && (
          <div className={styles.section}>
            <div className={styles.header}>Note</div>
            <div className={styles.entries}>
              {note ? (
                <div className={styles.note}>{note.value} oz</div>
              ) : (
                <div className={styles.noEntry}>
                  <img src={iconNote} alt="note" />
                  Not Logged
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default TrackersModal

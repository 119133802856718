import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@utils/hooks'
import { userRoles } from '@utils/user'

const RequireAuth = ({ children, ...rest }) => {
  const { user } = useAuth()
  const location = useLocation()

  if (!user || !userRoles.includes(user.role?.id)) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children
}

export default RequireAuth

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Modal from '@comp/Modal/Modal'
import SelectList, { SelectItem } from '@comp/SelectList/SelectList'
import { FormInputGroup } from '@comp/Form/Form'

import { useGetGoalsQuery, useSetClientGoalsMutation } from '@api/goalsApiSlice'

import { selectCurrentClient } from '@state/clientSlice'

import icon_close from '@assets/icon_close.svg'

import styles from './GoalsModal.module.scss'

const goalCategories = [
  { key: 'logging', title: 'Logging' },
  { key: 'movement', title: 'Movement' },
  { key: 'food_specifics', title: 'Food Specifics' },
  { key: 'beverages', title: 'Beverages' },
  { key: 'planning_prep', title: 'Planning Prep' },
  { key: 'mindfulness', title: 'Mindfulness' },
  { key: 'misc', title: 'Misc' },
]

const GoalsModal = ({ assignedGoals, onClose }) => {
  const client = useSelector(selectCurrentClient)

  const { data: goals } = useGetGoalsQuery()
  const [setGoals] = useSetClientGoalsMutation()

  const [selectedGoals, set_selectedGoals] = useState(assignedGoals)

  const [search, set_search] = useState('')

  const toggleGoal = (goal) => {
    if (selectedGoals.find(({ id }) => goal.id === id)) {
      set_selectedGoals(selectedGoals.filter(({ id }) => goal.id !== id))
    } else {
      set_selectedGoals([...selectedGoals, goal])
    }
  }

  const handleSave = async () => {
    await setGoals({
      clientId: client.id,
      previousGoals: assignedGoals.map(({ id }) => id),
      goals: selectedGoals.map(({ id }) => id),
    })
    onClose()
  }

  if (!goals) return null

  const matchesSearch = ({ name }) =>
    name.toLowerCase().includes(search.toLowerCase())

  return (
    <Modal onClose={handleSave} closeOnClickOutside={false}>
      <div
        className={styles.GoalsModal}
        data-testid="client-profile-goals-modal"
      >
        <div className={styles.header}>
          Goals
          <button onClick={handleSave}>
            <img src={icon_close} alt="close goals modal" />
          </button>
        </div>

        <div className={styles.searchContainer}>
          <FormInputGroup
            className={styles.search}
            value={search}
            onChange={set_search}
            placeholder="Search goals"
          />
        </div>

        <div className={styles.goals}>
          <SelectList className={styles.list} title="Active Goals">
            {selectedGoals.map((goal) => {
              return (
                <SelectItem
                  className={styles.item}
                  selected={selectedGoals.find(({ id }) => id === goal.id)}
                  onSelect={() => toggleGoal(goal)}
                  key={goal.id}
                >
                  {goal.name}
                </SelectItem>
              )
            })}
          </SelectList>

          {goalCategories.map(({ key, title }) => (
            <SelectList
              className={styles.list}
              title={title}
              initiallyOpen={false}
              disabled={!goals[key].find(matchesSearch)}
              key={key}
            >
              {goals[key].filter(matchesSearch).map((goal) => (
                <SelectItem
                  className={styles.item}
                  selected={selectedGoals.find(({ id }) => id === goal.id)}
                  onSelect={() => toggleGoal(goal)}
                  key={goal.id}
                >
                  {goal.name}
                </SelectItem>
              ))}
            </SelectList>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default GoalsModal

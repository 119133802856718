import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isBefore } from 'date-fns'
import Shiitake from 'shiitake'
import cs from 'classnames'
import { toast } from 'react-toastify'

import Modal from '@comp/Modal/Modal'
import Card from '@comp/Card/Card'
import Select from '@comp/Select/Select'
import Textarea from '@comp/Textarea/Textarea'
import Loader from '@comp/Loader/Loader'
import Button from '@comp/Button/Button'

import RecipientsModal from './RecipientsModal'

import {
  useCreateScheduledMessageMutation,
  useUpdateScheduledMessageMutation,
  useDeleteScheduledMessageMutation,
} from '@api/messagesApiSlice'

import { messageSet } from '@state/messageSlice'

import { getDateTimeOptions, getDateTimeOption, getUTCDatetime } from './utils'

import icon_pencil from '@assets/icon_pencil.svg'

import styles from './ScheduleModalMass.module.scss'

const ScheduleModalMass = ({ initialAllClients, initialGroups, onClose }) => {
  const dispatch = useDispatch()
  const editingMessage = useSelector(({ message }) => message.message)

  const [dateOptions, set_dateOptions] = useState([])
  const [timeOptions, set_timeOptions] = useState([])
  const [date, set_date] = useState()
  const [time, set_time] = useState()
  const [message, set_message] = useState(editingMessage?.text || '')
  const [error, set_error] = useState()
  const [showRecipientsModal, set_showRecipientsModal] = useState()
  const [allClients, set_allClients] = useState(
    initialAllClients || editingMessage?.allClients
  )
  const [selectedClients, set_selectedClients] = useState(
    editingMessage?.clients || []
  )
  const [selectedGroups, set_selectedGroups] = useState(
    initialGroups || editingMessage?.clientGroups || []
  )

  const [createScheduledMessage, { isLoading: isCreating }] =
    useCreateScheduledMessageMutation()

  const [updateScheduledMessage, { isLoading: isUpdating }] =
    useUpdateScheduledMessageMutation()

  const [deleteScheduledMessage, { isLoading: isDeleting }] =
    useDeleteScheduledMessageMutation()

  useEffect(() => {
    const FUTURE_DAYS = 14

    const { dateOptions, timeOptions } = getDateTimeOptions(FUTURE_DAYS)

    set_dateOptions(dateOptions)
    set_timeOptions(timeOptions)

    if (editingMessage) {
      const savedDatetime = new Date(editingMessage.datetime)
      const { dateOption, timeOption } = getDateTimeOption({
        date: savedDatetime,
        dateOptions,
        timeOptions,
      })

      set_date(dateOption.value)
      set_time(timeOption.value)
    } else {
      set_date(dateOptions[0].value)
      set_time(timeOptions[16].value)
    }
  }, [editingMessage])

  const handleSchedule = async () => {
    const utcStart = getUTCDatetime({ date, time })

    if (isBefore(utcStart, new Date())) {
      set_error('Scheduled time is in the past.')
      return
    }

    const payload = {
      text: message.trim(),
      datetime: utcStart,
      allClients: allClients || false,
      direct: false,
      clients: selectedClients.map(({ id }) => id),
      clientGroups: selectedGroups.map(({ id }) => id),
    }

    if (editingMessage) {
      await updateScheduledMessage({
        messageId: editingMessage.id,
        message: payload,
      })
    } else {
      await createScheduledMessage({ message: payload })
    }

    toast.success('Your group message has been scheduled')

    handleClose()
  }

  const handleDelete = async () => {
    const confirm = window.confirm('Delete scheduled message?')
    if (!confirm) return

    await deleteScheduledMessage({ messageId: editingMessage.id })
    handleClose()
  }

  const handleClose = () => {
    onClose()
    dispatch(messageSet(null))
  }

  const hasRecipients =
    allClients || selectedGroups.length || selectedClients.length
  let recipientsLabel = 'Recipients'
  if (allClients) recipientsLabel = 'All Clients'
  else if (selectedGroups.length || selectedClients.length) {
    const groupLabels = selectedGroups.map(({ name }) => name)
    const clientLabels = selectedClients.map(
      ({ firstName, lastName }) => `${firstName} ${lastName}`
    )
    recipientsLabel = [...groupLabels, ...clientLabels].join(', ')
  }

  return (
    <Modal
      className={styles.modal}
      onClose={handleClose}
      closeOnClickOutside={false}
    >
      <Card
        className={styles.ScheduleModalMass}
        title="Schedule Mass Message"
        onClose={handleClose}
      >
        <div
          className={styles.recipientsContainer}
          onClick={() => set_showRecipientsModal(true)}
        >
          <Shiitake
            className={cs(
              styles.recipients,
              hasRecipients && styles.hasRecipients
            )}
            lines={1}
          >
            {recipientsLabel}
          </Shiitake>
          <img src={icon_pencil} alt="edit" />
        </div>

        <div className={styles.datetime}>
          <Select
            className={styles.date}
            options={dateOptions}
            value={date}
            onChange={set_date}
            full
          />
          <Select
            className={styles.date}
            options={timeOptions}
            value={time}
            onChange={set_time}
            full
          />
        </div>
        {error && <div className={styles.error}>{error}</div>}
        <Textarea
          className={styles.message}
          value={message}
          onChange={set_message}
          placeholder="Message"
        />
        <Button
          className={styles.schedule}
          onClick={handleSchedule}
          disabled={!message.trim() || !hasRecipients}
          full
        >
          {editingMessage ? 'Save' : 'Schedule'}
        </Button>

        {editingMessage && (
          <div className={styles.deleteWrapper}>
            <span className={styles.delete} onClick={handleDelete}>
              delete message
            </span>
          </div>
        )}

        {showRecipientsModal && (
          <RecipientsModal
            onClose={() => set_showRecipientsModal(false)}
            allClients={allClients}
            set_allClients={set_allClients}
            selectedClients={selectedClients}
            set_selectedClients={set_selectedClients}
            selectedGroups={selectedGroups}
            set_selectedGroups={set_selectedGroups}
          />
        )}
      </Card>

      {(isCreating || isUpdating || isDeleting) && <Loader />}
    </Modal>
  )
}

export default ScheduleModalMass

import { useState, useEffect, useCallback } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Chat } from 'stream-chat-react'
import cs from 'classnames'

import Popup from '@comp/Popup/Popup'
import ChangePasswordModal from './ChangePasswordModal'

import { signedOut } from '@state/authSlice'
import { selectCurrentClient } from '@state/clientSlice'

import { setLocally } from '@utils/storage'
import { getInitials, getName } from '@utils/user'
import { useAuth } from '@utils/hooks'
import { chatClient, useChatClient } from '@utils/chat'

import iconOPN from '@assets/icon_opn.svg'
import iconChat from '@assets/icon_chat.svg'
import iconPerson from '@assets/icon_person.svg'
import iconGroups from '@assets/icon_groups.svg'
import iconBook from '@assets/icon_book.svg'
import iconSettings from '@assets/icon_settings.svg'
import iconPassword from '@assets/icon_password.svg'
import iconPower from '@assets/icon_power.svg'

import styles from './Main.module.scss'
import chatStyles from '@pages/Chat/Chat.module.scss'

const Main = ({ children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const [showProfileMenu, setShowProfileMenu] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [unreadChannels, set_unreadChannels] = useState()

  const { chatUser } = useChatClient(user)
  const client = useSelector(selectCurrentClient)

  const handleLogout = useCallback(() => {
    setLocally('userToken', null)
    dispatch(signedOut())
    navigate('/login')
  }, [navigate, dispatch])
  const initials = getInitials(user)

  useEffect(() => {
    if (!chatUser) return
    set_unreadChannels(chatUser.unread_channels)
  }, [chatUser])

  useEffect(() => {
    const listener = chatClient.on((event) => {
      if (event.unread_channels !== undefined) {
        set_unreadChannels(event.unread_channels)
      }
    })

    return () => listener.unsubscribe()
  }, [])

  useEffect(() => {
    let title = 'Nutritionist Dashboard'
    if (unreadChannels) title = `(${unreadChannels}) ${title}`
    document.title = title
  }, [unreadChannels])

  const avatar = user.profile_picture ? (
    <img src={user.profile_picture.url} alt={getName(user)} />
  ) : (
    initials
  )

  return (
    <div className={styles.Main}>
      <Chat
        client={chatClient}
        customClasses={{
          chat: cs('str-chat', chatStyles.chat),
          chatContainer: cs('str-chat__container', chatStyles.chatContainer),
          channelList: cs('str-chat-channel-list', chatStyles.channelsList),
        }}
      >
        <div className={styles.sidebar} data-testid="main-sidebar">
          <img className={styles.branding} src={iconOPN} alt="opn logo" />

          <nav className={styles.nav}>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNav : styles.navLink
              }
              // to={client ? `/chat/${client.id}` : '/chat'}
              to="/chat"
              data-testid="main-nav-chat"
            >
              <img src={iconChat} alt="chat" />
              {unreadChannels ? (
                <div className={styles.unread}>{unreadChannels}</div>
              ) : null}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNav : styles.navLink
              }
              to={client ? `/client/${client.id}` : '/client'}
              data-testid="main-nav-client"
            >
              <img src={iconPerson} alt="client" />
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNav : styles.navLink
              }
              to="/groups"
              data-testid="main-nav-groups"
            >
              <img src={iconGroups} alt="groups" />
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNav : styles.navLink
              }
              to="/resources"
              data-testid="main-nav-resources"
            >
              <img src={iconBook} alt="resources" />
            </NavLink>
            {user.admin && (
              <NavLink
                className={({ isActive }) =>
                  isActive ? styles.activeNav : styles.navLink
                }
                to="/admin"
                data-testid="main-nav-admin"
              >
                <img src={iconSettings} alt="admin" />
              </NavLink>
            )}
          </nav>
          <div className={styles.profileButton}>
            <button
              onClick={() => setShowProfileMenu(true)}
              data-testid="main-nav-profile"
            >
              {avatar}
            </button>
            {showProfileMenu && (
              <Popup
                className={styles.profileMenu}
                onClose={() => setShowProfileMenu(false)}
              >
                <div className={styles.profileCard}>
                  <div className={styles.header}>
                    <div className={styles.avatar}>{avatar}</div>
                    <div className={styles.name}>{getName(user)}</div>
                  </div>
                  <div className={styles.bio}>{user.bio} </div>
                  <div className={styles.buttons}>
                    {/*
                    <button>
                      <img src={iconPencil} alt="edit profile" />
                      Edit Profile
                    </button>
                    */}
                    <button
                      onClick={() => {
                        setShowChangePassword(true)
                        setShowProfileMenu(false)
                      }}
                    >
                      <img src={iconPassword} alt="change password" />
                      Change Password
                    </button>
                    <button onClick={handleLogout}>
                      <img src={iconPower} alt="logout" />
                      Log Out
                    </button>
                  </div>
                </div>
              </Popup>
            )}
          </div>
        </div>

        <div className={styles.content}>
          <Outlet />
        </div>

        {showChangePassword && (
          <ChangePasswordModal
            onClose={() => setShowChangePassword(false)}
            logout={handleLogout}
          />
        )}
      </Chat>
    </div>
  )
}

export default Main

import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'

import authReducer from '@state/authSlice'
import commonReducer from '@state/commonSlice'
import clientReducer from '@state/clientSlice'
import messageReducer from '@state/messageSlice'
import groupReducer from '@state/groupSlice'
import resourceReducer from '@state/resourceSlice'

import { authApi } from '@api/authApiSlice'
import { nutritionistApi } from '@api/nutritionistApiSlice'
import { analyticsApi } from '@api/analyticsApiSlice'
import { messagesApi } from '@api/messagesApiSlice'
import { goalsApi } from '@api/goalsApiSlice'
import { resourcesApi } from '@api/resourcesApiSlice'
import { adminApi } from '@api/adminApiSlice'
import { groupsApi } from '@api/groupsApiSlice'

import { rtkQueryErrorHandler } from '@middleware/errorMiddleware'

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  })

export const store = configureStore({
  reducer: {
    router: routerReducer,
    auth: authReducer,
    common: commonReducer,
    client: clientReducer,
    message: messageReducer,
    group: groupReducer,
    resource: resourceReducer,
    [authApi.reducerPath]: authApi.reducer,
    [nutritionistApi.reducerPath]: nutritionistApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [messagesApi.reducerPath]: messagesApi.reducer,
    [goalsApi.reducerPath]: goalsApi.reducer,
    [resourcesApi.reducerPath]: resourcesApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
  },
  middleware: [
    thunkMiddleware,
    authApi.middleware,
    nutritionistApi.middleware,
    analyticsApi.middleware,
    messagesApi.middleware,
    goalsApi.middleware,
    resourcesApi.middleware,
    adminApi.middleware,
    groupsApi.middleware,
    routerMiddleware,
    rtkQueryErrorHandler,
  ],
})

export const history = createReduxHistory(store)

import cs from 'classnames'

import icon_close from '@assets/icon_close.svg'

import styles from './Card.module.scss'

const Card = ({ className, children, title, onClose }) => {
  return (
    <div className={cs(styles.Card, className)}>
      {(title || onClose) && (
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          {onClose && (
            <button className={styles.closeButton} onClick={onClose}>
              <img src={icon_close} alt="close modal" />
            </button>
          )}
        </div>
      )}

      {children}
    </div>
  )
}

export default Card

import React from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'

import { Section, ListItem } from '../Components'

import { selectCurrentClient } from '@state/clientSlice'

import { isToday, isYesterday } from '@utils/date'

const About = ({ className }) => {
  const client = useSelector(selectCurrentClient)

  if (!client) return null

  const lastActive = client.lastActive ? new Date(client.lastActive) : null

  let lastActiveLabel = 'Never'
  if (lastActive) {
    lastActiveLabel = format(lastActive, 'MMM d, yyyy')
    if (isToday(lastActive)) lastActiveLabel = 'Today'
    else if (isYesterday(lastActive)) lastActiveLabel = 'Yesterday'
  }

  return (
    <Section className={className} title="About">
      <ListItem label="Last Online" category={lastActiveLabel} />
    </Section>
  )
}

export default About

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiHost } from '@api'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: '/auth/local',
        method: 'POST',
        body: {
          identifier: email,
          password,
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: {
          email,
          redirectUrl: `${process.env.REACT_APP_FRONTEND_HOST}/reset-password`,
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ password, passwordConfirmation, code }) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: {
          password,
          passwordConfirmation,
          code,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: ({ currentPassword, newPassword, newPasswordConfirmation }) => ({
        url: '/users/me/change-password',
        method: 'POST',
        body: {
          currentPassword,
          newPassword,
          newPasswordConfirmation,
        },
      }),
    }),
    getUser: builder.query({
      query: (token) => '/users/me',
    }),
  }),
})

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useGetUserQuery,
} = authApi

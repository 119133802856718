import React from 'react'

import Trackers from './Trackers/Trackers'
import About from './About/About'
import Goals from './Goals/Goals'
import Resources from './Resources/Resources'

import styles from './Profile.module.scss'

const Profile = () => {
  return (
    <div className={styles.Profile} data-testid="client-page-profile">
      <div className={styles.col}>
        <Trackers />
        <About />
      </div>

      <div className={styles.col}>
        <Goals />
        <Resources />
      </div>
    </div>
  )
}

export default Profile

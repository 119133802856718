export const convertMinutesToString = (m) => {
  m = parseInt(m, 10)
  const hours = Math.floor(m / 60)
  const minutes = m % 60

  let str = ''

  if (hours) {
    str += `${hours} hour`
    if (hours > 1) str += 's'
  }
  if (hours && minutes) str += ' '
  if (minutes) {
    str += `${minutes} minute`
    if (minutes > 1) str += 's'
  }
  return str
}

export const convertMinutesToTime = (m) => {
  m = parseInt(m, 10)
  const hours = Math.floor(m / 60)
  const minutes = m % 60

  return { hours, minutes }
}

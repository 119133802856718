import React from 'react'
import { Link } from 'react-router-dom'
import cs from 'classnames'

import Select from '@comp/Select/Select'
import Button from '@comp/Button/Button'

import * as styles from './Form.module.scss'

export const Form = ({ className, children, ...rest }) => (
	<form className={cs(className, styles.Form)} {...rest}>
		{children}
	</form>
)

export const FormContainer = ({ className, children }) => (
	<div className={cs(className, styles.Form)}>{children}</div>
)

export const FormTitle = ({ children, ...rest }) => (
	<div className={styles.FormTitle} {...rest}>
		{children}
	</div>
)

export const FormInputLabel = ({ className, id, label }) => (
	<label className={styles.FormInputLabel} htmlFor={id}>
		{label}
	</label>
)

export const FormInputGroup = ({ className, label, id, onChange, ...rest }) => (
	<div className={cs(className, styles.FormInputGroup)}>
		{label && <FormInputLabel id={id} label={label} />}
		<input
			id={id}
			aria-label={label}
			onChange={(e) => onChange(e.target.value)}
			{...rest}
		/>
	</div>
)

export const FormSelectGroup = ({ className, label, id, ...rest }) => (
	<div className={cs(className, styles.FormSelectGroup)}>
		{label && <FormInputLabel id={id} label={label} />}
		<Select id={id} {...rest} />
	</div>
)

export const FormButton = ({ className, label, ...rest }) => (
	<Button
		className={cs(className, styles.FormButton)}
		aria-label={label}
		full
		{...rest}
	>
		{label}
	</Button>
)

export const FormLink = ({ className, label, ...rest }) => (
	<Link className={cs(className, styles.FormLink)} {...rest}>
		{label}
	</Link>
)

export const FormInfo = ({ className, children }) => (
	<div className={styles.FormInfo}>{children}</div>
)

export const FormError = ({ className, children, ...rest }) => (
	<div className={styles.FormError} {...rest}>
		{children}
	</div>
)

export const Checkbox = (props) => {
	const { className, id, label, large, checked, onChange, ...rest } = props

	return (
		<label
			className={cs(className, styles.Checkbox, large && styles.large)}
			htmlFor={id}
		>
			<input
				id={id}
				type="checkbox"
				checked={checked}
				onChange={(e) => onChange && onChange(e.target.checked)}
				{...rest}
			/>
			{label && <span className={styles.label}>{label}</span>}
			<span className={styles.check} />
		</label>
	)
}

import React, { useState } from 'react'

import Modal from '@comp/Modal/Modal'
import Card from '@comp/Card/Card'
import SelectList, { SelectItem } from '@comp/SelectList/SelectList'
import { FormInputGroup } from '@comp/Form/Form'

import { useGetClientsQuery } from '@api/nutritionistApiSlice'
import { useGetClientGroupsQuery } from '@api/groupsApiSlice'

import { sortBy } from '@utils'

import styles from './RecipientsModal.module.scss'

const RecipientsModal = ({
  onClose,
  allClients,
  set_allClients,
  selectedClients,
  set_selectedClients,
  selectedGroups,
  set_selectedGroups,
}) => {
  const [search, set_search] = useState('')

  const { data: clients } = useGetClientsQuery()
  const { data: clientGroups } = useGetClientGroupsQuery()

  const filterBySearch = (collection, keys) =>
    (collection || []).filter((item) =>
      keys.reduce(
        (acc, key) =>
          acc || item[key].toLowerCase().includes(search.toLowerCase()),
        false
      )
    )

  const filteredClients = filterBySearch(clients, ['firstName', 'lastName'])

  const filteredGroups = filterBySearch(clientGroups, ['name'])

  const toggleClient = (client) => {
    const existing = selectedClients.find((c) => c.id === client.id)
    let newClients
    if (existing) newClients = selectedClients.filter((c) => c.id !== client.id)
    else newClients = [...selectedClients, client]
    set_selectedClients(newClients)
  }

  const toggleGroup = (group) => {
    const existing = selectedGroups.find((g) => g.id === group.id)
    let newGroups
    if (existing) newGroups = selectedGroups.filter((g) => g.id !== group.id)
    else newGroups = [...selectedGroups, group]
    set_selectedGroups(newGroups)
  }

  return (
    <Modal
      onClose={onClose}
      className={styles.modal}
      closeOnClickOutside={false}
    >
      <Card className={styles.card} onClose={onClose} title="Clients">
        <div className={styles.RecipientsModal}>
          <FormInputGroup
            className={styles.search}
            value={search}
            onChange={set_search}
            placeholder="Search clients"
          />

          <div className={styles.clients}>
            <div className={styles.allClientsContainer}>
              <SelectItem
                className={styles.item}
                selected={allClients}
                onSelect={set_allClients}
              >
                <div className={styles.avatar}>AC</div>
                <div className={styles.name}>All Clients</div>
              </SelectItem>
            </div>

            <SelectList
              className={styles.list}
              title="Currently Selected"
              disabled={allClients}
            >
              {selectedGroups &&
                sortBy(selectedGroups, 'name').map((group) => (
                  <GroupItem
                    group={group}
                    selected={selectedGroups.find(({ id }) => id === group.id)}
                    onSelect={() => toggleGroup(group)}
                    key={group.id}
                  />
                ))}
              {selectedClients &&
                sortBy(selectedClients, 'firstName').map((client) => (
                  <ClientItem
                    client={client}
                    selected={selectedClients.find(
                      ({ id }) => id === client.id
                    )}
                    onSelect={() => toggleClient(client)}
                    key={client.id}
                  />
                ))}
            </SelectList>

            <SelectList
              className={styles.list}
              title="Groups"
              disabled={allClients}
            >
              {filteredGroups &&
                filteredGroups.map((group) => (
                  <GroupItem
                    group={group}
                    selected={selectedGroups.find(({ id }) => id === group.id)}
                    onSelect={() => toggleGroup(group)}
                    key={group.id}
                  />
                ))}
            </SelectList>

            <SelectList
              className={styles.list}
              title="Clients"
              disabled={allClients}
            >
              {filteredClients &&
                filteredClients.map((client) => (
                  <ClientItem
                    client={client}
                    selected={selectedClients.find(
                      ({ id }) => id === client.id
                    )}
                    onSelect={() => toggleClient(client)}
                    key={client.id}
                  />
                ))}
            </SelectList>
          </div>
        </div>
      </Card>
    </Modal>
  )
}

const GroupItem = ({ group, selected, onSelect }) => {
  const { name } = group

  return (
    <SelectItem className={styles.item} selected={selected} onSelect={onSelect}>
      <div className={styles.avatar}>{name[0]}</div>
      <div className={styles.name}>{name}</div>
    </SelectItem>
  )
}

const ClientItem = ({ client, selected, onSelect }) => {
  const { firstName, lastName } = client

  return (
    <SelectItem className={styles.item} selected={selected} onSelect={onSelect}>
      <div className={styles.avatar}>{firstName[0] + lastName[0]}</div>
      <div className={styles.name}>
        {firstName} {lastName}
      </div>
    </SelectItem>
  )
}

export default RecipientsModal

import React from 'react'
import cs from 'classnames'

import styles from './Textarea.module.scss'

const Textarea = ({ className, onChange, ...props }) => {
  return (
    <textarea
      className={cs(className, styles.Textarea)}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    />
  )
}

export default Textarea

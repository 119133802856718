import { useState } from 'react'
import Calendar from 'react-calendar'
import cs from 'classnames'
import { subMonths, addMonths } from 'date-fns'

import Popup from '@comp/Popup/Popup'

import { format, dateFormat } from '@utils/date'

import iconNext from '@assets/icon-next.svg'
import iconPrev from '@assets/icon-prev.svg'

import './CalendarPopup.scss'
import styles from './CalendarPopup.module.scss'

const getNavLabels = (date) => ({
  prev: format(subMonths(date, 1), 'LLL'),
  next: format(addMonths(date, 1), 'LLL'),
})

const CalendarPopup = ({
  className,
  tileClassName,
  date,
  setDate,
  markedDates,
  onMonthChange,
  onClose,
  ...rest
}) => {
  const [navLabels, set_navLabels] = useState(getNavLabels(date))

  return (
    <Popup
      className={cs(styles.CalendarPopup, className)}
      onClose={onClose}
      data-testid={rest['data-testid']}
    >
      <Calendar
        value={date}
        onChange={setDate}
        prevLabel={
          <div className={styles.navLabel}>
            <img src={iconPrev} alt="prev month" />
            {navLabels.prev}
          </div>
        }
        nextLabel={
          <div className={styles.navLabel}>
            {navLabels.next}
            <img src={iconNext} alt="next month" />
          </div>
        }
        prev2Label={null}
        next2Label={null}
        navigationLabel={({ date }) => format(date, 'LLL')}
        tileContent={({ date }) =>
          markedDates.has(format(date, dateFormat)) && (
            <div className={styles.marker} />
          )
        }
        tileClassName={(args) =>
          cs(
            styles.tile,
            typeof tileClassName === 'function'
              ? tileClassName(args)
              : tileClassName
          )
        }
        formatShortWeekday={(locale, date) => format(date, 'EEEEE')}
        onActiveStartDateChange={({ activeStartDate }) => {
          set_navLabels(getNavLabels(activeStartDate))
          onMonthChange(activeStartDate)
        }}
      />
    </Popup>
  )
}

export default CalendarPopup

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiHost } from '@api'

import { sortBy } from '@utils'

export const nutritionistApi = createApi({
  reducerPath: 'nutritionistApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Target'],
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => ({
        url: '/users/clients?limit=500',
        method: 'GET',
      }),
      transformResponse: (response) => sortBy(response, 'firstName'),
    }),
    getClientMealDates: builder.query({
      query: ({ clientId, start, end }) => ({
        url: `/user-meals/dates?client=${clientId}&start=${start}&end=${end}`,
        method: 'GET',
      }),
    }),
    getClientMeals: builder.query({
      query: ({ clientId, start, end }) => ({
        url: `/user-meals?client=${clientId}&start=${start}&end=${end}`,
        method: 'GET',
      }),
    }),
    getClientTrackers: builder.query({
      query: ({ clientId, start, end }) => ({
        url: `/user-trackers?client=${clientId}&start=${start}&end=${end}`,
        method: 'GET',
      }),
    }),
    getClientTargets: builder.query({
      query: ({ clientId }) => ({
        url: `/user-targets?client=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['Target'],
    }),
    createClientTargets: builder.mutation({
      query: ({ clientId, body }) => ({
        url: `/user-targets?client=${clientId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Target'],
    }),
    updateClientTargets: builder.mutation({
      query: ({ clientId, targetProfileId, body }) => ({
        url: `/user-targets/${targetProfileId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Target'],
    }),
  }),
})

export const {
  useGetClientsQuery,
  useGetClientMealDatesQuery,
  useGetClientMealsQuery,
  useGetClientTrackersQuery,
  useGetClientTargetsQuery,
  useCreateClientTargetsMutation,
  useUpdateClientTargetsMutation,
} = nutritionistApi

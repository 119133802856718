import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import cs from 'classnames'

import Modal from '@comp/Modal/Modal'
import Select from '@comp/Select/Select'
import Button from '@comp/Button/Button'
import ScrollList from '@comp/ScrollList/ScrollList'

import {
  useCreateClientTargetsMutation,
  useUpdateClientTargetsMutation,
} from '@api/nutritionistApiSlice'

import { selectCurrentClient } from '@state/clientSlice'

import { hashByProperty } from '@utils'
import { defaultGroups, formatTargetsProfileForApi } from '@utils/meals'
import { defaultTrackers } from '@utils/trackers'

import icon_close from '@assets/icon_close.svg'

import styles from './TargetsModal.module.scss'

const foodTypes = [
  { value: 'single', text: 'Single Target' },
  { value: 'range', text: 'Ranged Target' },
  { value: 'none', text: 'Tracking - No Target' },
  { value: 'hidden', text: 'Hidden' },
]

const trackerTypes = [
  { value: 'none', text: 'Tracking - No Target' },
  { value: 'hidden', text: 'Hidden' },
]

const TargetsModal = ({ targets, targetProfile, onClose }) => {
  const client = useSelector(selectCurrentClient)

  const [createTargets] = useCreateClientTargetsMutation()
  const [updateTargets] = useUpdateClientTargetsMutation()

  const [targetValues, set_targetValues] = useState()

  const updateTarget = ({ name, min, max }) => {
    set_targetValues({
      ...targetValues,
      [name]: {
        name,
        min,
        max,
      },
    })
  }

  const handleSave = async () => {
    if (!targetProfile.id) {
      await createTargets({
        clientId: client.id,
        body: {
          ...formatTargetsProfileForApi(targetValues),
          name: 'Default',
          start: new Date(),
        },
      })
    } else {
      await updateTargets({
        clientId: client.id,
        targetProfileId: targetProfile.id,
        body: formatTargetsProfileForApi(targetValues),
      })
    }
    onClose()
  }

  useEffect(() => {
    set_targetValues(
      hashByProperty(
        [...defaultGroups, ...defaultTrackers].map(({ name }) => ({
          name,
          min: targets[name] ? targets[name].min : null,
          max: targets[name] ? targets[name].max : null,
        })),
        'name'
      )
    )
  }, [targets])

  if (!targetValues) return null

  return (
    <Modal onClose={onClose} closeOnClickOutside={false}>
      <div
        className={styles.TargetsModal}
        data-testid="client-profile-targets-modal"
      >
        <div className={styles.header}>
          Targets
          <button onClick={onClose}>
            <img src={icon_close} alt="close targets modal" />
          </button>
        </div>
        <div className={styles.labels}>
          <div>Tracker</div>
          <div>Tracker Type</div>
          <div>Value</div>
        </div>
        <ScrollList className={styles.targets}>
          {defaultGroups.map(({ name, color }) => (
            <Target
              name={name}
              color={color}
              min={targetValues[name].min}
              max={targetValues[name].max}
              types={foodTypes}
              onChange={updateTarget}
              key={name}
            />
          ))}
          {defaultTrackers.map(({ name, icon }) => (
            <Target
              name={name}
              icon={icon}
              min={targetValues[name].min}
              types={trackerTypes}
              onChange={updateTarget}
              key={name}
            />
          ))}
        </ScrollList>
        <div className={styles.footer}>
          <Button
            className={styles.save}
            onClick={handleSave}
            data-testid="save"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const Target = ({ name, color, icon, min, max, types, onChange }) => {
  const increment = 0.5

  const getOptions = (start = 0) => {
    const options = []
    for (let i = start; i <= 15; i += increment) {
      options.push({ value: i, text: i })
    }
    return options
  }

  let type
  if (min === -1) {
    type = 'hidden'
  } else if (min === null) {
    type = 'none'
  } else if (max !== null) {
    type = 'range'
  } else {
    type = 'single'
  }

  const isTarget = (min) => min !== null && min >= 0

  const handleTypeChange = (type) => {
    switch (type) {
      case 'range':
        onChange({
          name,
          min: isTarget(min) ? min : 0,
          max: (isTarget(min) ? min : 0) + increment,
        })
        break
      case 'none':
        onChange({ name, min: null, max: null })
        break
      case 'hidden':
        onChange({ name, min: -1, max: null })
        break
      default:
        onChange({ name, min: 0, max: null })
    }
  }

  return (
    <div className={styles.Target} data-testid={`targets-modal-${name}`}>
      <div className={styles.name}>
        {icon ? (
          <img src={icon} alt={name} />
        ) : (
          <div className={styles.color} style={{ background: color }} />
        )}
        {name}
      </div>
      <div className={styles.type}>
        <Select
          options={types}
          value={type}
          onChange={handleTypeChange}
          full
          data-testid="tracker-type"
        />
      </div>
      {isTarget(min) && (
        <div className={cs(styles.values, max && styles.range)}>
          <Select
            options={getOptions()}
            value={min}
            onChange={(value) => {
              const newMax =
                max && max <= value ? (max = value + increment) : max
              onChange({ name, min: value, max: newMax })
            }}
            full
            label={!!max && 'Min'}
            data-testid="tracker-min"
          />
          {!!max && (
            <Select
              options={getOptions(min + increment)}
              value={max}
              onChange={(value) => onChange({ name, max: value, min })}
              full
              label={!!max && 'Max'}
              data-testid="tracker-max"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default TargetsModal

import { createSlice } from '@reduxjs/toolkit'

import { startOfWeek, endOfWeek } from '@utils/date'

const initialState = {
  client: null,
  weekStart: startOfWeek(new Date()),
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    clientSelected(state, { payload }) {
      state.client = payload
      state.weekStart = startOfWeek(new Date())
    },
    weekStartSet(state, { payload }) {
      state.weekStart = payload
    },
  },
})

export const { clientSelected, weekStartSet } = clientSlice.actions

export default clientSlice.reducer

export const selectCurrentClient = (state) => state.client.client
export const selectWeekStart = (state) => state.client.weekStart
export const selectWeekEnd = (state) => endOfWeek(state.client.weekStart)

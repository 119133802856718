import React, { useRef } from 'react'
import cs from 'classnames'
import Shiitake from 'shiitake'

import { formatRelativeToday } from '@utils/date'

import styles from './ChannelPreview.module.scss'

const UnMemoizedChannelPreviewMessenger = (props) => {
  const { active, channel, displayTitle, setActiveChannel, unread, watchers } =
    props

  const channelPreviewButton = useRef(null)

  const avatarName =
    channel.data.client.firstName[0] + channel.data.client.lastName[0]

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers)
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur()
    }
  }

  const messages = channel.state.messageSets[0]?.messages
  const lastMessage = messages.length && messages[messages.length - 1]
  const lastMessageText = lastMessage ? lastMessage.text : 'No messages yet'
  const lastMessageDate = lastMessage
    ? formatRelativeToday(new Date(lastMessage.created_at), true)
    : null

  return (
    <button
      aria-label={`Select Channel: ${displayTitle || ''}`}
      aria-selected={active}
      className={cs(styles.preview, active && styles.active)}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
      role="option"
    >
      <div
        className={cs(styles.marker, unread && unread >= 1 && styles.unread)}
      />
      <div className={styles.avatar}>{avatarName}</div>
      <div className={styles.right}>
        <div className={styles.header}>
          <div className={styles.name}>{displayTitle}</div>
          {lastMessage ? (
            <div className={styles.date}>
              {lastMessageDate[0].toUpperCase() + lastMessageDate.slice(1)}
            </div>
          ) : null}
        </div>
        <Shiitake className={styles.lastMessage} lines={2}>
          {lastMessageText}
        </Shiitake>
      </div>
    </button>
  )
}

export const ChannelPreviewMessenger = React.memo(
  UnMemoizedChannelPreviewMessenger
)
export default ChannelPreviewMessenger

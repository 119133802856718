export const userRoles = [4, 5, 6]

export const validatePassword = (password) =>
  password.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  )

export const getName = (user) =>
  `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`

export const getInitials = (user) =>
  user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()

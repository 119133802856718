import { useRef, useEffect, useCallback } from 'react'
import cs from 'classnames'

import styles from './Popup.module.scss'

const Popup = ({
  className,
  children,
  onClose,
  closeOnClickOutside = true,
  ...rest
}) => {
  const nodeRef = useRef(null)

  const handleClick = useCallback(
    (e) => {
      if (!closeOnClickOutside) return
      if (!nodeRef.current || !nodeRef.current.contains(e.target)) onClose()
    },
    [onClose, closeOnClickOutside]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClick])

  return (
    <div ref={nodeRef} className={cs(className, styles.Popup)} {...rest}>
      {children}
    </div>
  )
}

export default Popup

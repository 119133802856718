import { useState, useEffect, useRef, useMemo } from 'react'
import styles from './Modal.module.scss'

import ReactDOM from 'react-dom'
import cs from 'classnames'

import Loader from '@comp/Loader/Loader'

const portalRoot = document.body

const Portal = ({ children }) => {
  const element = useMemo(() => document.createElement('div'), [])

  useEffect(() => {
    portalRoot.appendChild(element)
    return () => {
      portalRoot.removeChild(element)
    }
  }, [element])
  return ReactDOM.createPortal(children, element)
}

function Modal({
  className,
  contentsClassName,
  children,
  onClose,
  ready = true,
  noOverlay,
  closeOnClickOutside = true,
}) {
  const [show, setShow] = useState(false)
  const modalRef = useRef(null)
  const contentsRef = useRef(null)

  useEffect(() => {
    modalRef.current.focus()
    setShow(true)
  }, [])

  const handleKeyDown = (e) => {
    if (e.keyCode === 27 && onClose) {
      onClose(e)
      e.stopPropagation()
    }
  }

  return (
    <Portal>
      <div
        ref={modalRef}
        className={cs(
          styles.Modal,
          className,
          show && styles.show,
          noOverlay && styles.noOverlay
        )}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={() => closeOnClickOutside && onClose()}
      >
        {!ready && <Loader />}
        <div
          ref={contentsRef}
          className={cs(
            styles.contents,
            contentsClassName,
            ready && show && styles.show
          )}
          onClick={(e) => {
            closeOnClickOutside && e.stopPropagation()
          }}
        >
          {children}
        </div>
      </div>
    </Portal>
  )
}

export default Modal

import React from 'react'
import ReactMarkdown from 'react-markdown'

import Modal from '@comp/Modal/Modal'

import icon_close from '@assets/icon_close.svg'

import { getResourceName } from '@utils/resources'

import styles from './ResourceModal.module.scss'

const ResourceModal = ({ resource, onClose }) => {
  const { coverImage, name, internalName, content, ctaLink, ctaText } = resource

  return (
    <Modal onClose={onClose}>
      <div className={styles.ResourceModal} data-testid="resource-modal">
        <div className={styles.cover}>
          <img src={coverImage.url} alt={name} />

          <div className={styles.close} data-testid="resource-modal-close">
            <img src={icon_close} onClick={onClose} alt="close" />
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.name}>
            {getResourceName({ name, internalName })}
          </div>
          <ReactMarkdown className={styles.content} linkTarget="_blank">
            {content}
          </ReactMarkdown>

          {ctaLink && ctaText && (
            <a
              href={ctaLink}
              target="_blank"
              rel="noreferrer"
              className={styles.cta}
            >
              {ctaText}
            </a>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ResourceModal

import { utcToZonedTime } from 'date-fns-tz'

import { changeCamelCase, removeLeadingZero } from '@utils'
import { defaultTrackers } from '@utils/trackers'

export const sortMealsByDatetime = (meals) =>
  meals.sort((a, b) => (a.datetime < b.datetime ? -1 : 1))

export const formatMealFromApi = (meal) => {
  const { datetime, timezone, createdAt, ...rest } = meal

  const formatted = {
    ...changeCamelCase(rest),
    date: utcToZonedTime(new Date(datetime), timezone),
    time: utcToZonedTime(new Date(datetime), timezone),
    createdAt: utcToZonedTime(new Date(createdAt), timezone),
    foods: changeCamelCase(assignFoodIds(meal.foods, meal.id)),
    datetime: utcToZonedTime(new Date(datetime), timezone),
    timezone,
  }

  return formatted
}

export const formatTargetsProfile = (targets) => {
  if (!targets || targets.data === null) return
  return [...defaultGroups, ...defaultTrackers].reduce(
    (acc, { name }) => ({
      ...acc,
      [name]: {
        min:
          typeof targets[`${name}_min`] === 'number'
            ? targets[`${name}_min`]
            : null,
        max:
          typeof targets[`${name}_max`] === 'number'
            ? targets[`${name}_max`]
            : null,
      },
    }),
    {}
  )
}

export const formatTargetLabel = (target) => {
  if (!target || target.min === null) return 'None'
  if (target.min === -1) return 'Hidden'
  const min =
    typeof target.min === 'number' ? removeLeadingZero(target.min) : 'None'
  const max =
    typeof target.max === 'number' ? removeLeadingZero(target.max) : 'None'
  if (min === 'None' && max === 'None') return 'None'
  if (max === 'None') return min
  return `${min}-${max}`
}

export const formatTargetsProfileForApi = (targets) => {
  return Object.values(targets).reduce(
    (acc, { name, min, max }) => ({
      ...acc,
      [`${name}_min`]: targets[name].min,
      [`${name}_max`]: targets[name].max,
    }),
    {}
  )
}

export const assignFoodIds = (foods, mealId) =>
  foods.map((food, index) => ({
    ...food,
    id: `M${mealId}F${index}`,
  }))

export const defaultGroups = [
  {
    name: 'fat',
    abbr: 'fat',
    color: '#9D64E5',
  },
  {
    name: 'protein',
    abbr: 'protein',
    color: '#DB5353',
  },
  {
    name: 'fruit',
    abbr: 'fruit',
    color: '#F0BA30',
  },
  {
    name: 'starch',
    abbr: 'starch',
    color: '#EF873C',
  },
  {
    name: 'dairy',
    abbr: 'dairy',
    color: '#4EB3EC',
  },
  {
    name: 'vegetable',
    abbr: 'veg.',
    color: '#58C777',
  },
  {
    name: 'condiment',
    abbr: 'cond.',
    color: '#000000',
  },
]

export const calcMealTotals = (meal) => {
  const totals = defaultGroups.map((group) => ({ ...group, quantity: 0 }))

  meal.foods.forEach((food) => {
    defaultGroups.forEach(({ name: type }) => {
      if (food[type]) {
        totals.find(({ name }) => name === type).quantity +=
          food.quantity * food[type]
      }
    })
  })

  return totals.filter(({ quantity }) => quantity)
}

export const getDelta = ({ value, min, max }) => {
  // no target or hidden target
  if (min === null || min === -1) return null

  // single target
  if (max === null) return value - min

  // range targets
  if (value >= min && value <= max) return 0
  if (value > max) return value - max
  return value - min
}

import React from 'react'
import cs from 'classnames'

import styles from './Loader.module.scss'

const Loader = () => (
  <div className={styles.Loader}>
    <div className={styles.doublebounce1}></div>
    <div className={styles.doublebounce2}></div>
  </div>
)

export const Spinner = ({ className, color = 'white' }) => (
  <div
    className={cs(styles.spinner, className)}
    style={{ borderLeft: `1.1em solid ${color}` }}
  ></div>
)

export default Loader

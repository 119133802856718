import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Form,
  FormTitle,
  FormInputGroup,
  FormButton,
  FormLink,
} from '@comp/Form/Form'

import { useForgotPasswordMutation } from '@api/authApiSlice'

import iconOpn from '@assets/icon-opn-large.svg'

import styles from './Account.module.scss'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      await forgotPassword({ email }).unwrap()
      navigate('/forgot-password-confirm')
    } catch {
      navigate('/account-error')
    }
  }

  return (
    <div className={styles.Account}>
      <img className={styles.icon} src={iconOpn} alt="onpoint nutrition logo" />
      <Form className={styles.form} onSubmit={onSubmit}>
        <FormTitle>Forgot Password</FormTitle>
        <div className={styles.details}>
          Enter your email address and we’ll send you a link to reset your
          password.
        </div>
        <FormInputGroup
          id="email"
          type="email"
          label="Email Address"
          value={email}
          onChange={setEmail}
          required
        />
        <FormButton
          label="Reset Password"
          type="submit"
          onClick={onSubmit}
          isLoading={isLoading}
        />
        <div className={styles.linkWrapper}>
          <FormLink className={styles.link} to="/login" label="Cancel" />
        </div>
      </Form>
    </div>
  )
}

export default ForgotPassword

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import GoalsModal from './GoalsModal'
import { Section, ListItem } from '../Components'

import { useGetClientGoalsQuery } from '@api/goalsApiSlice'

import { selectCurrentClient } from '@state/clientSlice'

const Goals = () => {
  const [showGoalsModal, set_showGoalsModal] = useState()
  const client = useSelector(selectCurrentClient)
  const { data: goals } = useGetClientGoalsQuery(
    { clientId: client?.id },
    { skip: !client }
  )

  if (!goals) return null

  return (
    <Section
      title="Goals"
      onEdit={() => set_showGoalsModal(true)}
      data-testid="client-profile-goals"
    >
      {goals.length ? (
        goals.map(({ id, emoji, name, category }) => (
          <ListItem label={`${emoji} ${name}`} category={category} key={id} />
        ))
      ) : (
        <ListItem category="No goals assigned" />
      )}

      {showGoalsModal && (
        <GoalsModal
          assignedGoals={goals}
          onClose={() => set_showGoalsModal(false)}
        />
      )}
    </Section>
  )
}

export default Goals

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { useGetClientGroupsQuery } from '@api/groupsApiSlice'

import { groupSelector, groupSelected } from '@state/groupSlice'

import GroupsList from './GroupsList'
import Group from './Group'

import styles from './Groups.module.scss'

const Groups = () => {
  const dispatch = useDispatch()
  const { groupId } = useParams()
  const selectedGroup = useSelector(groupSelector)

  const { data: clientGroups } = useGetClientGroupsQuery()

  useEffect(() => {
    if (!clientGroups) return
    const nextGroup = groupId
      ? clientGroups.find(({ id }) => id.toString() === groupId)
      : null
    dispatch(groupSelected(nextGroup))
  }, [dispatch, groupId, clientGroups])

  return (
    <div className={styles.Groups} data-testid="groups-page">
      <GroupsList clientGroups={clientGroups} selectedGroup={selectedGroup} />
      <Group selectedGroup={selectedGroup} />
    </div>
  )
}

export default Groups

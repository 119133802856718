import React, { useState } from 'react'
import cs from 'classnames'
import { useSelector } from 'react-redux'

import TargetsModal from './TargetsModal'
import { Section } from '../Components'
import { Spinner } from '@comp/Loader/Loader'

import { useGetClientTargetsQuery } from '@api/nutritionistApiSlice'

import { selectCurrentClient } from '@state/clientSlice'

import {
  defaultGroups,
  formatTargetsProfile,
  formatTargetLabel,
} from '@utils/meals'
import { defaultTrackers } from '@utils/trackers'

import styles from './Trackers.module.scss'

const Trackers = () => {
  const [showTargetsModal, set_showTargetsModal] = useState()
  const client = useSelector(selectCurrentClient)
  const { data: targetProfile } = useGetClientTargetsQuery(
    { clientId: client?.id },
    { skip: !client }
  )

  const targets = formatTargetsProfile(targetProfile)

  if (!targetProfile) return null

  return (
    <Section
      title="Trackers"
      onEdit={() => set_showTargetsModal(true)}
      data-testid="client-profile-targets"
    >
      <Targets targets={targets} />

      {showTargetsModal && (
        <TargetsModal
          targets={targets}
          targetProfile={targetProfile}
          onClose={() => set_showTargetsModal(false)}
        />
      )}
    </Section>
  )
}

export const Targets = ({ className, targets }) => {
  if (!targets) return <Spinner />

  const shownTrackers = defaultTrackers.filter(
    ({ name }) => targets[name].min !== -1
  )

  return (
    <div
      className={cs(className, styles.conversions)}
      data-testid="conversions"
    >
      {[...defaultGroups, ...shownTrackers].map(({ name, color, icon }) => (
        <div
          className={styles.conversion}
          key={name}
          data-testid={`tracker-${name}`}
        >
          {icon ? (
            <img src={icon} alt={name} />
          ) : (
            <div
              className={styles.color}
              style={{ background: color }}
              data-testid="tracker-value"
            >
              {formatTargetLabel(targets[name])}
            </div>
          )}
          {name}
        </div>
      ))}
    </div>
  )
}

export default Trackers

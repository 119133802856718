import React from 'react'
import cs from 'classnames'

import styles from './Components.module.scss'

import icon_pencil from '@assets/icon_pencil.svg'

export const Section = ({ className, children, title, onEdit, ...rest }) => {
  return (
    <div
      className={cs(className, styles.Section)}
      data-testid={rest['data-testid']}
    >
      <div className={styles.header}>
        {title}

        {onEdit ? (
          <button onClick={onEdit} data-testid="client-profile-section-edit">
            <img src={icon_pencil} alt="edit" />
          </button>
        ) : null}
      </div>

      {children}
    </div>
  )
}

export const ListItem = ({ label, category }) => (
  <div className={styles.ListItem}>
    <div className={styles.label}>{label}</div>

    {category && (
      <div className={styles.category}>{category.split('_').join(' ')}</div>
    )}
  </div>
)

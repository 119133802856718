import { useState, useRef, useEffect } from 'react'
import * as d3 from 'd3'
import cs from 'classnames'

import { defaultGroups, formatTargetLabel } from '@utils/meals'
import { format } from '@utils/date'
import { round } from '@utils'

import styles from './FoodlogChart.module.scss'

const CHART_HEIGHT = 550

const FoodlogChart = ({ start, end, totals, averages, targets }) => {
  const containerRef = useRef()
  const chartRef = useRef()
  const [conversion, set_conversion] = useState('fat')

  const parseDate = (date) => d3.timeParse('%Y-%m-%d')(date)

  const margin = { top: 20, bottom: 50, left: 30, right: 0 }
  const height = CHART_HEIGHT - margin.top - margin.bottom
  const backgroundHeight = height + 20

  const { color } = defaultGroups.find(({ name }) => name === conversion)

  useEffect(() => {
    // Set up chart
    const svg = d3.select(chartRef.current)
    d3.selectAll('svg#foodlog #plot').remove()
    const plot = svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('id', 'plot')

    // Prepare data
    const history = totals.map((t) => ({
      date: parseDate(t.date),
      value: t[conversion],
    }))
    const target = targets[conversion]
    const targetValues = [
      {
        date: parseDate(start),
        min: target.min,
        max: target.max === null ? target.min : target.max,
      },
      {
        date: parseDate(end),
        min: target.min,
        max: target.max === null ? target.min : target.max,
      },
    ]

    // Set the ranges
    const width = containerRef.current.clientWidth - margin.left - margin.right
    const dayWidth = width / history.length
    const date = d3.scaleTime().range([0, width - dayWidth])
    const value = d3.scaleLinear().range([height, 0])

    // Scale the range of the history
    date.domain(d3.extent(history, ({ date }) => date))
    const maxTotal = d3.max(history, ({ value }) => Math.floor(value + 1))
    const maxTarget = d3.max(targetValues, ({ max }) => Math.floor(max + 1))
    value.domain([0, Math.max(maxTotal, maxTarget)]).range([height, 0])

    // Setup tooltip
    const tooltip = {
      container: d3.select(`.${styles.tooltip}`),
      date: d3.select('#analytics_foodlog_tooltip_date'),
      target: d3.select('#analytics_foodlog_tooltip_target'),
      value: d3.select('#analytics_foodlog_tooltip_value'),
      average: d3.select('#analytics_foodlog_tooltip_average'),
    }

    // Draw chart
    addBackground()
    addDayColumns()
    addTargetsArea()
    addTargetLine()
    addTotalsLine()
    addDots()
    addAverageLine()
    addXAxis()
    addYAxis()

    function addBackground() {
      plot
        .append('rect')
        .attr('y', -10)
        .attr('width', width)
        .attr('height', backgroundHeight)
        .attr('rx', 10)
        .attr('ry', 10)
        .attr('class', styles.plot)
    }

    function addDayColumns() {
      plot
        .selectAll('.day')
        .data(history)
        .enter()
        .append('rect')
        .attr('x', (d, index) => index * dayWidth)
        .attr('y', -10)
        .attr('width', dayWidth)
        .attr('height', backgroundHeight)
        .attr('rx', 10)
        .attr('ry', 10)
        .attr('class', styles.day)
        .on('mouseenter', (event, { date, value }) => {
          let targetLabel = formatTargetLabel(targets[conversion])
          if (targetLabel === 'None') targetLabel = 'No Target'
          else if (targetLabel === 'Hidden') {
          } else targetLabel += ` ${conversion}`

          tooltip.container
            .style('opacity', 1)
            .style('left', `${event.x}px`)
            .style('top', `${event.y}px`)
          tooltip.date.html(format(date, 'E, MMM d, yyyy'))
          tooltip.target.html(targetLabel)
          tooltip.value.html(`${value || 0} ${conversion}`)
          tooltip.average.html(
            `${round(averages[conversion] || 0, 2)} ${conversion}`
          )
        })
        .on('mousemove', (event) => {
          tooltip.container
            .style('left', `${event.x}px`)
            .style('top', `${event.y}px`)
        })
        .on('mouseleave', () => {
          tooltip.container
            .style('opacity', 0)
            .style('left', `-1000px`)
            .style('top', `-1000px`)
        })
    }

    function addTargetsArea() {
      const targetDate = d3.map(targetValues, ({ date }) => date)
      const targetMin = d3.map(targetValues, ({ min }) => min)
      const targetMax = d3.map(targetValues, ({ max }) => max)
      const I = d3.range(targetDate.length)
      const D = d3.map(
        targetValues,
        (d, i) =>
          !isNaN(targetDate[i]) &&
          targetMin[i] !== null &&
          !isNaN(targetMin[i]) &&
          targetMax[i] !== null &&
          !isNaN(targetMax[i])
      )

      const area = d3
        .area()
        .defined((i) => D[i])
        .curve(d3.curveLinear)
        .x((i) => date(targetDate[i]) + dayWidth / 2)
        .y0((i) => value(targetMin[i]) - 1.5)
        .y1((i) => value(targetMax[i]) - 1.5)

      plot.append('path').attr('d', area(I)).attr('class', styles.targetArea)
    }

    function addTargetLine() {
      const minTargetLine = d3
        .line()
        .defined((d) => d.min !== null)
        .x((d) => date(d.date) + dayWidth / 2)
        .y((d) => value(d.min))

      plot
        .append('path')
        .datum(targetValues)
        .attr('class', styles.target)
        .attr('d', minTargetLine)
    }

    function addTotalsLine() {
      const totalsLine = d3
        .line()
        .defined((d) => d.value !== null)
        .x((d) => date(d.date) + dayWidth / 2)
        .y((d) => value(d.value))

      plot
        .append('path')
        .datum(history)
        .attr('class', styles.totals)
        .attr('d', totalsLine)
        .attr('stroke', color)
    }

    function addDots() {
      plot
        .selectAll('.dot')
        .data(history.filter((d) => d.value !== null))
        .enter()
        .append('circle')
        .attr('class', styles.dot)
        .attr('cx', (d) => date(d.date) + dayWidth / 2)
        .attr('cy', (d) => value(d.value))
        .attr('r', 5)
        .attr('fill', color)
    }

    function addAverageLine() {
      const average = averages[conversion] || 0
      plot
        .append('line')
        .attr('x1', dayWidth / 2)
        .attr('y1', value(average))
        .attr('x2', width - dayWidth / 2)
        .attr('y2', value(average))
        .attr('stroke', color)
        .style('stroke-dasharray', '4,4')
        .attr('class', styles.average)
    }

    function addXAxis() {
      const dateTicks = date
        .ticks(d3.timeDay.every(1))
        .filter((date, index) => index % Math.round(history.length / 7) === 0)
      const xAxis = d3
        .axisBottom(date)
        .tickValues(dateTicks)
        .tickFormat(d3.timeFormat('%a, %b %_d'))
        .ticks(7)

      plot
        .append('g')
        .attr('transform', `translate(${dayWidth / 2}, ${height + 15})`)
        .call(xAxis)
        .selectAll('text')
        .attr('class', styles.tickX)
        .style('text-anchor', 'center')
    }

    function addYAxis() {
      const yAxisTicks = value.ticks().filter((tick) => Number.isInteger(tick))
      const yAxis = d3
        .axisLeft(value)
        .tickValues(yAxisTicks)
        .tickFormat(d3.format('d'))

      plot
        .append('g')
        .attr('transform', `translate(0, 0)`)
        .attr('class', styles.tickY)
        .call(yAxis)
    }

    plot.selectAll('.domain').remove()
    plot.selectAll('.tick line').remove()
  }, [
    start,
    end,
    totals,
    averages,
    targets,
    conversion,
    color,
    height,
    margin.bottom,
    margin.left,
    margin.right,
    margin.top,
    backgroundHeight,
  ])

  return (
    <div
      className={styles.FoodlogChart}
      history-testid="client-page-analytics"
      ref={containerRef}
    >
      <div className={styles.conversionBar}>
        {defaultGroups.map(({ color, name }) => (
          <div
            className={cs(
              styles.conversion,
              name === conversion && styles.selected
            )}
            onClick={() => set_conversion(name)}
            key={name}
          >
            <div className={styles.dot} style={{ background: color }} />
            {name}
          </div>
        ))}
      </div>

      <svg
        style={{
          height: CHART_HEIGHT,
          width: '100%',
          marginRight: '0px',
          marginLeft: '0px',
        }}
        id="foodlog"
        ref={chartRef}
      />

      <div className={styles.tooltip}>
        <div className={styles.label}>Date</div>
        <div id="analytics_foodlog_tooltip_date" />
        <div className={styles.label}>Target</div>
        <div id="analytics_foodlog_tooltip_target" />
        <div className={styles.label}>Logged</div>
        <div id="analytics_foodlog_tooltip_value" />
        <div className={styles.label}>Average</div>
        <div id="analytics_foodlog_tooltip_average" />
      </div>
    </div>
  )
}

export default FoodlogChart

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { useGetResourceCollectionsQuery } from '@api/resourcesApiSlice'

import { collectionSelector, collectionSelected } from '@state/resourceSlice'

import CollectionsList from './CollectionsList'
import Collection from './Collection'

import styles from './Resources.module.scss'

const Resources = () => {
  const dispatch = useDispatch()
  const { collectionId } = useParams()
  const selectedCollection = useSelector(collectionSelector)

  const { data: resourceCollections } = useGetResourceCollectionsQuery()

  useEffect(() => {
    if (!resourceCollections) return
    const nextCollection = collectionId
      ? resourceCollections.find(({ id }) => id.toString() === collectionId)
      : null
    dispatch(collectionSelected(nextCollection))
  }, [dispatch, collectionId, resourceCollections])

  return (
    <div className={styles.Resources} data-testid="resources-page">
      <CollectionsList
        resourceCollections={resourceCollections}
        selectedCollection={selectedCollection}
      />
      <Collection
        resourceCollections={resourceCollections}
        selectedCollection={selectedCollection}
      />
    </div>
  )
}

export default Resources

import React from 'react'
import { formatInTimeZone } from 'date-fns-tz'

import { sortBy } from '@utils'

import styles from './PastMassMessage.module.scss'

const PastMassMessage = ({ datetime, text, allClients, clients }) => {
  const dateString = formatInTimeZone(
    new Date(datetime),
    'America/New_York',
    'MM/dd/yy'
  )
  const timeString = formatInTimeZone(
    new Date(datetime),
    'America/New_York',
    '  h:mm a'
  )
  const recipientsString = allClients
    ? 'All Clients'
    : sortBy(clients, 'firstName')
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(', ')

  return (
    <div className={styles.PastMassMessage}>
      <div className={styles.text}>{text}</div>
      <div className={styles.footer}>
        {dateString} at {timeString} to {recipientsString}
      </div>
    </div>
  )
}

export default PastMassMessage

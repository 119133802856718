import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser, selectUserToken } from '@state/authSlice'

export const useAuth = () => {
  const user = useSelector(selectCurrentUser)
  const token = useSelector(selectUserToken)

  return useMemo(() => ({ user, token }), [user, token])
}

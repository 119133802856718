import React from 'react'
import { useDispatch } from 'react-redux'
import { formatInTimeZone } from 'date-fns-tz'
import Shiitake from 'shiitake'

import Popup from '@comp/Popup/Popup'
import Button from '@comp/Button/Button'

import { messageSet } from '@state/messageSlice'

import styles from './ScheduledMessagesList.module.scss'

const ScheduledMessagesList = ({
  messages,
  onClose,
  openScheduleModal,
  closeOnClickOutside,
}) => {
  const dispatch = useDispatch()

  const handleClickMessage = (message) => {
    dispatch(messageSet(message))
    openScheduleModal()
  }

  return (
    <Popup
      className={styles.ScheduledMessagesList}
      onClose={onClose}
      closeOnClickOutside={closeOnClickOutside}
    >
      {messages.length ? (
        messages.map((message) => (
          <Message
            {...message}
            onClick={() => handleClickMessage(message)}
            key={message.id}
          />
        ))
      ) : (
        <div className={styles.noMessages}>No scheduled messages</div>
      )}

      <div className={styles.button}>
        <Button className={styles.newMessage} onClick={openScheduleModal} full>
          New Message
        </Button>
      </div>
    </Popup>
  )
}

const Message = ({ datetime, text, onClick }) => {
  return (
    <div className={styles.Message} onClick={onClick}>
      <div className={styles.datetime}>
        {formatInTimeZone(
          new Date(datetime),
          'America/New_York',
          'M/d/yy @ h:mm a'
        )}
      </div>

      <Shiitake className={styles.text} lines={3}>
        {text}
      </Shiitake>
    </div>
  )
}

export default ScheduledMessagesList

import { useState } from 'react'
import { toast } from 'react-toastify'

import Modal from '@comp/Modal/Modal'
import Card from '@comp/Card/Card'

import { Form, FormInputGroup, FormButton, FormError } from '@comp/Form/Form'

import { useChangePasswordMutation } from '@api/authApiSlice'

import { validatePassword } from '@utils/user'
import { extractErrorMessage } from '@utils/errors'

import styles from './ChangePasswordModal.module.scss'

const ChangePasswordModal = ({ onClose, logout }) => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [error, setError] = useState(null)

  const [changePassword, { isLoading }] = useChangePasswordMutation()

  const onSubmit = async (e) => {
    e.preventDefault()

    if (newPassword !== newPasswordConfirmation) {
      setError('Your passwords do not match.')
      return
    }

    if (
      process.env.REACT_APP_ENV !== 'development' &&
      !validatePassword(newPassword)
    ) {
      setError(
        'Password must contain lowercase letter, uppercase letter, number, special character, and a minimum of 8 characters.'
      )
      return
    }

    try {
      await changePassword({
        currentPassword,
        newPassword,
        newPasswordConfirmation,
      }).unwrap()
      toast.success('Your password has been changed')
      onClose()
      logout()
    } catch (error) {
      setError(extractErrorMessage(error))
    }
  }

  return (
    <Modal className={styles.ChangePasswordModal} onClose={onClose}>
      <Card title="Change Password" onClose={onClose}>
        <Form className={styles.form} onSubmit={onSubmit}>
          <FormInputGroup
            id="currentPassword"
            type="password"
            label="Current Password"
            value={currentPassword}
            onChange={setCurrentPassword}
            required
          />
          <FormInputGroup
            id="newPassword"
            type="password"
            label="New Password"
            value={newPassword}
            onChange={setNewPassword}
            required
          />
          <FormInputGroup
            id="confirm"
            type="password"
            label="Confirm New Password"
            value={newPasswordConfirmation}
            onChange={setNewPasswordConfirmation}
            required
          />
          <FormButton
            label="Save"
            type="submit"
            onClick={onSubmit}
            isLoading={isLoading}
          />
          {error && <FormError>{error}</FormError>}
        </Form>
      </Card>
    </Modal>
  )
}

export default ChangePasswordModal

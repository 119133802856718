import React from 'react'

import { removeLeadingZero } from '@utils'

import styles from './Conversions.module.scss'

const Conversions = ({ className, conversions, showLabel }) => {
  return (
    <div className={styles.Conversions}>
      {conversions.map(({ name, color, quantity }) => (
        <div className={styles.conversion} key={name}>
          <div className={styles.quantity} style={{ background: color }}>
            {removeLeadingZero(quantity)}
          </div>
          {showLabel && name}
        </div>
      ))}
    </div>
  )
}

export default Conversions

import changeCase from 'change-case-object'

export const clamp = ({ value, min, max }) =>
  Math.min(max, Math.max(min, value))

export const round = (value, decimals = 0) =>
  Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)

export const removeLeadingZero = (number) =>
  number.toString().replace(/^0\./, '.')

export const changeCamelCase = (obj) => changeCase.camelCase(obj)

export const hashByProperty = (array, property) =>
  array.reduce(
    (acc, item) => ({
      ...acc,
      [item[property]]: item,
    }),
    {}
  )

export const hashByPropertyAsArray = (array, property) =>
  array.reduce(
    (acc, item) => ({
      ...acc,
      [item[property]]: acc[item[property]]
        ? [...acc[item[property]], item]
        : [item],
    }),
    {}
  )

const toLowerCase = (value) =>
  value?.toLowerCase ? value.toLowerCase() : value

export const sortBy = (array, property, reverse) => {
  const result = [...array].sort((a, b) =>
    toLowerCase(a[property]) < toLowerCase(b[property]) ? -1 : 1
  )
  reverse && result.reverse()
  return result
}

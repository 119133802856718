import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiHost } from '@api'

import { changeCamelCase } from '@utils'

export const messagesApi = createApi({
  reducerPath: 'messagesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Message'],
  endpoints: (builder) => ({
    getScheduledMessages: builder.query({
      query: () => ({
        url: '/messages',
        method: 'GET',
      }),
      providesTags: ['Message'],
      transformResponse: (response) =>
        changeCamelCase(
          response.sort((a, b) => (a.datetime > b.datetime ? 1 : -1))
        ),
    }),
    createScheduledMessage: builder.mutation({
      query: ({ message }) => ({
        url: `/messages`,
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['Message'],
    }),
    updateScheduledMessage: builder.mutation({
      query: ({ messageId, message }) => ({
        url: `/messages/${messageId}`,
        method: 'PUT',
        body: message,
      }),
      invalidatesTags: ['Message'],
    }),
    deleteScheduledMessage: builder.mutation({
      query: ({ messageId }) => ({
        url: `/messages/${messageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Message'],
    }),
  }),
})

export const {
  useGetScheduledMessagesQuery,
  useCreateScheduledMessageMutation,
  useUpdateScheduledMessageMutation,
  useDeleteScheduledMessageMutation,
} = messagesApi

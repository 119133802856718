import { useState } from 'react'

import Conversions from '@comp/Conversions/Conversions'
import Popup from '@comp/Popup/Popup'

import DailyDeltasChart from './DailyDeltasChart'
import TargetsPopup from './TargetsPopup'
import TrackersModal from './TrackersModal'

import { format, getDayIndex } from '@utils/date'
import { calcMealTotals, defaultGroups, getDelta } from '@utils/meals'
import { defaultTrackers } from '@utils/trackers'
import { convertMinutesToString } from '@utils/math'

import iconMovement from '@assets/icon-movement.svg'
import iconWater from '@assets/icon-water.svg'
import iconSleep from '@assets/icon-sleep.svg'
import iconWeight from '@assets/icon-weight.svg'
import iconNote from '@assets/icon-note.svg'

import styles from './DayColumn.module.scss'

const DayColumn = ({
  date,
  meals,
  totals,
  targets,
  movements,
  water,
  weight,
  sleep,
  note,
  index,
}) => {
  const [showTargetsPopup, set_showTargetsPopup] = useState(false)
  const [showTrackersModal, set_showTrackersModal] = useState()

  const deltas = defaultGroups.map(({ name, color, abbr }, i) => {
    const value = totals[name]
    const { min, max } = targets[name]
    const delta = getDelta({ value, min, max })

    return {
      delta,
      name,
      color,
      abbr,
      index: i,
    }
  })

  const movementsTotal = movements
    ? movements.reduce((acc, { value }) => acc + value, 0)
    : 0

  const activeTrackers = defaultTrackers
    .map(({ name }) => name)
    .filter((name) => targets[name].min !== -1)

  return (
    <div className={styles.DayColumn}>
      <div className={styles.header}>{format(date, 'eeee, LLL d')}</div>
      <div className={styles.analytics}>
        {meals.length ? (
          <DailyDeltasChart
            deltas={deltas}
            index={index}
            onClick={() => set_showTargetsPopup(true)}
          />
        ) : (
          <div className={styles.bar} />
        )}
        {showTargetsPopup && (
          <TargetsPopup
            date={date}
            deltas={deltas}
            totals={totals}
            targets={targets}
            index={index}
            onClose={() => set_showTargetsPopup(false)}
          />
        )}
      </div>
      <div className={styles.data}>
        <div className={styles.meals}>
          {meals.length > 0 ? (
            meals.map((meal) => <Meal {...meal} key={meal.id} />)
          ) : (
            <div className={styles.empty}>No food logged</div>
          )}
        </div>
        <div
          className={styles.trackers}
          onClick={() => set_showTrackersModal(true)}
        >
          {activeTrackers.includes('movement') && (
            <div className={styles.tracker}>
              <img src={iconMovement} alt="movement" />
              {movementsTotal ? (
                <div className={styles.label}>{movementsTotal} minutes</div>
              ) : (
                <div className={styles.noValue}>— </div>
              )}
            </div>
          )}

          {activeTrackers.includes('water') && (
            <div className={styles.tracker}>
              <img src={iconWater} alt="water" />
              {water ? (
                <div className={styles.label}>{water?.value} oz</div>
              ) : (
                <div className={styles.noValue}>—</div>
              )}
            </div>
          )}

          {activeTrackers.includes('sleep') && (
            <div className={styles.tracker}>
              <img src={iconSleep} alt="sleep" />
              {sleep ? (
                <div className={styles.label}>
                  {convertMinutesToString(sleep?.value)}
                </div>
              ) : (
                <div className={styles.noValue}>—</div>
              )}
            </div>
          )}

          {activeTrackers.includes('weight') && (
            <div className={styles.tracker}>
              <img src={iconWeight} alt="weight" />
              {weight ? (
                <div className={styles.label}>{weight?.value} lbs</div>
              ) : (
                <div className={styles.noValue}>—</div>
              )}
            </div>
          )}

          {activeTrackers.includes('note') && (
            <div className={styles.tracker}>
              <img src={iconNote} alt="note" />
              {note ? (
                <div className={styles.label} style={{ fontStyle: 'italic' }}>
                  Click to view
                </div>
              ) : (
                <div className={styles.noValue}>—</div>
              )}
            </div>
          )}
        </div>
      </div>
      {showTrackersModal && (
        <TrackersModal
          date={format(date, 'eeee, MMM d, yyyy')}
          movements={movements}
          water={water}
          sleep={sleep}
          weight={weight}
          note={note}
          activeTrackers={activeTrackers}
          onClose={() => set_showTrackersModal(false)}
        />
      )}
    </div>
  )
}

const Meal = (meal) => {
  const [showDetails, set_showDetails] = useState()
  const [detailsRight, set_detailsRight] = useState(true)

  const { mealType, time, date, timezone, foods, comment } = meal

  return (
    <div
      className={styles.Meal}
      onClick={(e) => {
        set_detailsRight(date.getDay() < 4)
        set_showDetails(true)
      }}
    >
      <div className={styles.header}>
        <div className={styles.mealType}>{mealType}</div>
        <div className={styles.time}>
          {format(time, 'h:mm a', { timeZone: timezone })}
        </div>
      </div>
      <div className={styles.foods}>
        {foods
          .map(({ name, ingredient }) =>
            ingredient ? name : `${name} (custom)`
          )
          .join(', ')}
      </div>
      <Conversions conversions={calcMealTotals(meal)} />
      {comment && <div className={styles.commentMarker} />}
      {showDetails && (
        <MealDetails
          meal={meal}
          onClose={() => set_showDetails(false)}
          detailsRight={detailsRight}
        />
      )}
    </div>
  )
}

const MealDetails = ({ meal, onClose, detailsRight }) => {
  const { mealType, createdAt, time, date, timezone, foods, comment } = meal

  const orientation = getDayIndex(date, timezone) < 5 ? 'right' : 'left'

  return (
    <Popup
      className={styles.MealDetails}
      onClose={onClose}
      style={{ [orientation]: -445 }}
    >
      <div className={styles.mealType}>{mealType}</div>
      <div className={styles.time}>
        {format(time, 'h:mm a zzz · EEEE, MMM d, yyyy', { timeZone: timezone })}
      </div>
      <div className={styles.created}>
        Logged{' '}
        {format(createdAt, 'h:mm a zzz · EEEE, MMM d, yyyy', {
          timeZone: timezone,
        })}
      </div>
      <div className={styles.foods}>
        {foods.map(({ id, name, ingredient, image, quantity, measurement }) => (
          <div className={styles.Food} key={id}>
            {image ? (
              <img className={styles.image} src={image?.url} alt={name} />
            ) : (
              <div className={styles.noImage} />
            )}
            <div className={styles.name}>
              {ingredient ? name : `${name} (custom)`}
            </div>
            <div className={styles.quantity}>
              {quantity} {measurement && `x ${measurement}`}
            </div>
          </div>
        ))}
      </div>
      <Conversions conversions={calcMealTotals(meal)} size="medium" />
      {comment && <div className={styles.comment}>{comment}</div>}
    </Popup>
  )
}

export default DayColumn

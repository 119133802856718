import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { subDays, subWeeks, subMonths } from 'date-fns'

import FoodlogChart from './FoodlogChart'
import AveragesChart from './AveragesChart'
import WeightChart from './WeightChart'
import StatsTable from './StatsTable'

import DropdownButton from '@comp/DropdownButton/DropdownButton'
import Popup from '@comp/Popup/Popup'

import { selectCurrentClient } from '@state/clientSlice'

import {
  useGetFoodlogTotalsQuery,
  useGetFoodlogAveragesQuery,
  useGetFoodlogStatsQuery,
  useGetWeightsQuery,
} from '@api/analyticsApiSlice'
import { useGetClientTargetsQuery } from '@api/nutritionistApiSlice'

import { format, dateFormat } from '@utils/date'
import { formatTargetsProfile } from '@utils/meals'

import styles from './Analytics.module.scss'

const yesterday = subDays(new Date(), 1)

const periods = [
  {
    text: 'Last Week',
    start: subWeeks(new Date(), 1),
    end: yesterday,
  },
  {
    text: 'Last 2 Weeks',
    start: subWeeks(new Date(), 2),
    end: yesterday,
  },
  {
    text: 'Last Month',
    start: subMonths(new Date(), 1),
    end: yesterday,
  },
  {
    text: 'Last 6 Months',
    start: subMonths(new Date(), 6),
    end: yesterday,
  },
]

const Analytics = () => {
  const [period, set_period] = useState(periods[0])
  const [showPeriods, set_showPeriods] = useState()
  const client = useSelector(selectCurrentClient)

  const { data: foodlogTotals } = useGetFoodlogTotalsQuery(
    {
      clientId: client?.id,
      start: format(period.start, dateFormat),
      end: format(period.end, dateFormat),
    },
    { skip: !client }
  )
  const { data: foodlogAverages } = useGetFoodlogAveragesQuery(
    {
      clientId: client?.id,
      start: format(period.start, dateFormat),
      end: format(period.end, dateFormat),
    },
    { skip: !client }
  )
  const { data: stats } = useGetFoodlogStatsQuery(
    {
      clientId: client?.id,
      start: format(period.start, dateFormat),
      end: format(period.end, dateFormat),
    },
    { skip: !client }
  )
  const { data: weights } = useGetWeightsQuery(
    {
      clientId: client?.id,
      start: format(period.start, dateFormat),
      end: format(period.end, dateFormat),
    },
    { skip: !client }
  )
  const { data: targetProfile } = useGetClientTargetsQuery(
    { clientId: client?.id },
    { skip: !client }
  )

  const targets = useMemo(
    () => formatTargetsProfile(targetProfile),
    [targetProfile]
  )

  return (
    <div className={styles.Analytics} data-testid="client-page-analytics">
      <div className={styles.header}>
        <DropdownButton
          className={styles.periodButton}
          onClick={() => set_showPeriods(!showPeriods)}
        >
          {period.text}
        </DropdownButton>
        {showPeriods && (
          <Popup
            className={styles.periodCard}
            onClose={() => set_showPeriods(false)}
          >
            {periods.map((p) => (
              <div
                className={styles.period}
                key={p.text}
                onClick={() => {
                  set_period(p)
                  set_showPeriods(false)
                }}
              >
                {p.text}
              </div>
            ))}
          </Popup>
        )}
      </div>

      <div className={styles.foodlog}>
        <div className={styles.title}>Food Groups</div>
        {foodlogTotals && targets && (
          <FoodlogChart {...foodlogTotals} targets={targets} />
        )}
      </div>

      <div className={styles.averages}>
        <div className={styles.title}>Target Accuracy & Consistency</div>
        {foodlogAverages && <AveragesChart {...foodlogAverages} />}
      </div>

      <div className={styles.weight}>
        <div className={styles.title}>Weight</div>
        {weights && <WeightChart {...weights} />}
      </div>

      <div className={styles.stats}>
        <div className={styles.title}>Stats</div>
        <StatsTable foodlogAverages={foodlogAverages} stats={stats} />
      </div>
    </div>
  )
}

export default Analytics

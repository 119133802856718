import React from 'react'
import cs from 'classnames'

import Accordion from '@comp/Accordion/Accordion'

import styles from './SelectList.module.scss'

const SelectList = ({
  className,
  title,
  initiallyOpen,
  disabled,
  children,
}) => {
  return (
    <Accordion
      className={cs(className, styles.SelectList)}
      title={title}
      initiallyOpen={initiallyOpen}
      disabled={disabled}
    >
      {children}
    </Accordion>
  )
}

export const SelectItem = ({
  className,
  selected = false,
  onSelect,
  children,
  label,
}) => {
  return (
    <div
      className={cs(className, styles.SelectItem)}
      onClick={() => onSelect(!selected)}
    >
      <div className={styles.content}>
        <input
          className={styles.checkbox}
          type="checkbox"
          checked={selected}
          onChange={() => {}}
        />
        {children ? children : <div className={styles.label}>{label}</div>}
      </div>
    </div>
  )
}

export default SelectList

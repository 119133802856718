import { useRef, useEffect } from 'react'
import * as d3 from 'd3'

import { clamp } from '@utils'

import styles from './DailyDeltasChart.module.scss'

const DailyDeltasChart = ({ deltas, index, onClick }) => {
  const containerRef = useRef()
  const chartRef = useRef()
  const chartId = `daily_delta_${index}`

  const margin = { top: 10, bottom: 10, left: 20, right: 20 }
  const height = 62 - margin.top - margin.bottom

  useEffect(() => {
    const width = containerRef.current.clientWidth - margin.left - margin.right
    const svg = d3.select(chartRef.current)
    d3.selectAll(`svg#${chartId} #plot`).remove()

    // set the ranges
    const conversion = d3.scaleLinear().range([0, width])
    const value = d3.scaleLinear().range([height, 0])

    // Scale the range of the history
    conversion.domain(d3.extent(deltas, ({ index }) => index))
    value.domain([-3, 3]).range([height, 0])

    const plot = svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('id', 'plot')

    // Add delta lines
    plot
      .selectAll('.delta')
      .data(deltas)
      .enter()
      .append('line')
      .attr('x1', ({ index }) => conversion(index))
      .attr('x2', ({ index }) => conversion(index))
      .attr('y1', value(0))
      .attr('y2', ({ delta }) =>
        value(clamp({ value: delta, min: -3, max: 3 }))
      )
      .attr('stroke', ({ color }) => color)
      .attr('class', styles.delta)
  }, [
    chartId,
    deltas,
    height,
    margin.top,
    margin.bottom,
    margin.left,
    margin.right,
  ])

  return (
    <div
      className={styles.DailyDeltasChart}
      ref={containerRef}
      onClick={onClick}
    >
      <svg
        style={{
          height: 62,
          width: '100%',
          marginRight: '0px',
          marginLeft: '0px',
        }}
        id={chartId}
        ref={chartRef}
      />
    </div>
  )
}

export default DailyDeltasChart

import iconMovement from '@assets/icon-movement.svg'
import iconWater from '@assets/icon-water.svg'
import iconSleep from '@assets/icon-sleep.svg'
import iconWeight from '@assets/icon-weight.svg'
import iconNote from '@assets/icon-note.svg'

export const defaultTrackers = [
  { name: 'movement', icon: iconMovement },
  { name: 'water', icon: iconWater },
  { name: 'sleep', icon: iconSleep },
  { name: 'weight', icon: iconWeight },
  { name: 'note', icon: iconNote },
]

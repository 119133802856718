import { addDays } from 'date-fns'
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz'

export const TIMEZONE = 'America/New_York'

export const getDateTimeOptions = (futureDays) => {
  const dateOptions = []
  const timeOptions = []

  for (let i = 0; i < futureDays; i++) {
    const date = addDays(new Date(), i)
    let text
    if (i === 0) text = 'Today'
    else if (i === 1) text = 'Tomorrow'
    else text = formatInTimeZone(date, TIMEZONE, 'EEEE, MMM dd')

    dateOptions.push({ value: date.toISOString(), text })
  }

  for (let i = 0; i < 48; i++) {
    const value = i * 30
    const hours = Math.floor(i / 2)
    const mins = (i % 2) * 30
    let hoursText = hours
    let pm = false
    if (!hours) hoursText = 12
    if (hours === 12) pm = true
    if (hours > 12) {
      hoursText -= 12
      pm = true
    }

    const text = `${hoursText}:${mins ? mins : '00'} ${pm ? 'PM' : 'AM'}`
    timeOptions.push({ value, text })
  }

  return { dateOptions, timeOptions }
}

export const getDateTimeOption = ({ date, dateOptions, timeOptions }) => {
  const dateOption = dateOptions.find(({ value }) => {
    const optionDate = formatInTimeZone(new Date(value), TIMEZONE, 'yyyy-MM-dd')
    return optionDate === formatInTimeZone(date, TIMEZONE, 'yyyy-MM-dd')
  })

  const timeOption = timeOptions.find(({ value }) => {
    const timeString = formatInTimeZone(date, TIMEZONE, 'H:mm')
    let [hours, mins] = timeString.split(':')
    hours = Number(hours)
    mins = Number(mins)
    return value === hours * 60 + mins
  })

  return { dateOption, timeOption }
}

export const getUTCDatetime = ({ date, time }) => {
  const timezoneOffset = getTimezoneOffset(TIMEZONE)

  const dateStringZoned = formatInTimeZone(
    new Date(date),
    TIMEZONE,
    'yyyy-MM-dd'
  )
  const utcStart = new Date(
    new Date(`${dateStringZoned}T00:00:00.000Z`).setMinutes(time) -
      timezoneOffset
  )

  return utcStart
}

import axios from 'axios'

import userApi from './user'
import foodlogApi from './foodlog'

import { getLocally } from '@utils/storage'

function getEnvironment() {
  if (process.env.NODE_ENV === 'production') {
    return { apiHost: process.env.REACT_APP_API_HOST }
  }

  const env = process.env.REACT_APP_ENV || 'default'
  const hosts = {
    default: process.env.REACT_APP_API_HOST,
    development: process.env.REACT_APP_API_HOST,
    testing: process.env.REACT_APP_API_HOST_TESTING,
    staging: process.env.REACT_APP_API_HOST_STAGING,
    production: process.env.REACT_APP_API_HOST_PROD,
  }
  return { apiHost: hosts[env] }
}

export const { apiHost } = getEnvironment()

const logMeta = {
  // version,
  // releaseChannel,
  // deviceName,
  // nativeAppVersion,
  // nativeBuildVersion,
}

const api = (options) => {
  const instance = axios.create({
    baseURL: apiHost,
    timeout: 3000,
    ...options,
  })
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Server error')
        console.log(error.response)
        log().post('', {
          ...logMeta,
          event: 'Server error',
          error: error.response,
        })
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('Network error')
        console.log(error.request)
        log().post('', {
          ...logMeta,
          event: 'Network error',
          error: error.request,
        })
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
        log().post('', {
          ...logMeta,
          event: 'General error',
          error: error.message,
        })
      }
      return Promise.reject(error)
    }
  )
  return instance
}

const authApi = async () => {
  const userToken = await getLocally('userToken')

  return api({
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  })
}

export const log = (options) =>
  axios.create({
    // baseURL: logglyUrl,
    ...options,
  })

const apiAll = {
  ...userApi.getApi({ api, authApi, log, logMeta }),
  ...foodlogApi.getApi({ api, authApi, log, logMeta }),
}

export default apiAll

import React, { useState, useEffect } from 'react'
import cs from 'classnames'

import ResourceModal from './ResourceModal'
import ResourceCard from './ResourceCard'

import { getResourceName } from '@utils/resources'

import icon_search from '@assets/icon_search_grey.svg'
import icon_close from '@assets/icon_close_grey.svg'

import styles from './Collection.module.scss'

const Collection = ({ resourceCollections, selectedCollection }) => {
  const [searchValue, set_searchValue] = useState('')
  const [selectedResource, set_selectedResource] = useState()

  useEffect(() => {
    set_searchValue('')
  }, [selectedCollection])

  const search = (
    <div className={styles.searchContainer}>
      <input
        className={styles.search}
        value={searchValue}
        onChange={(e) => set_searchValue(e.target.value)}
        placeholder="Search resources"
      />
      <div className={styles.searchIcon}>
        {searchValue ? (
          <img
            src={icon_close}
            onClick={() => set_searchValue('')}
            alt="clear search"
          />
        ) : (
          <img src={icon_search} alt="search" />
        )}
      </div>
    </div>
  )

  const resourceModal = selectedResource ? (
    <ResourceModal
      resource={selectedResource}
      onClose={() => set_selectedResource(null)}
    />
  ) : null

  const filterResourcesBySearch = (resources) =>
    resources.filter(
      ({ name, internalName, shortDescription }) =>
        getResourceName({ name, internalName })
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        shortDescription.toLowerCase().includes(searchValue.toLowerCase())
    )

  if (selectedCollection) {
    const resources = filterResourcesBySearch(selectedCollection.resources)

    return (
      <div className={styles.Collection} data-testid="collection">
        <div className={styles.header}>
          <div className={styles.name} data-testid="collection-name">
            {selectedCollection.name}
          </div>

          {search}
        </div>

        {searchValue && (
          <div
            className={cs(
              styles.subheader,
              !resources.length && styles.noBorder
            )}
          >
            <div className={styles.count}>
              {searchValue &&
                (resources.length
                  ? `${resources.length} result${
                      resources.length > 1 ? 's' : ''
                    }`
                  : 'No results')}
            </div>
          </div>
        )}

        <div className={styles.resourcesGrid}>
          {resources.map((resource) => (
            <ResourceCard
              resource={resource}
              onSelect={() => set_selectedResource(resource)}
              key={resource.id}
            />
          ))}
        </div>

        {resourceModal}
      </div>
    )
  }

  if (!resourceCollections) return null

  const filteredCollections = resourceCollections.filter(
    ({ resources }) => filterResourcesBySearch(resources).length
  )

  return (
    <div className={styles.Collection} data-testid="collection">
      <div className={styles.header}>
        <div className={styles.name} data-testid="collection-name">
          All Resources
        </div>

        {search}
      </div>

      {filteredCollections.length ? (
        filteredCollections.map(({ id, name, resources }) => {
          const filteredResources = filterResourcesBySearch(resources)

          if (!filteredResources.length) return null

          return (
            <div className={styles.collectionSection} key={id}>
              <div className={styles.subheader}>
                <div className={styles.collectionName}>{name}</div>

                <div className={styles.count}>
                  {searchValue &&
                    `${filteredResources.length} result${
                      filteredResources.length > 1 ? 's' : ''
                    }`}
                </div>
              </div>

              <div className={styles.resourcesGrid}>
                {filteredResources.map((resource) => (
                  <ResourceCard
                    resource={resource}
                    onSelect={() => set_selectedResource(resource)}
                    key={resource.id}
                  />
                ))}
              </div>
            </div>
          )
        })
      ) : (
        <div className={cs(styles.subheader, styles.noBorder)}>
          <div className={styles.count}>No results</div>
        </div>
      )}

      {resourceModal}
    </div>
  )
}

export default Collection

import React from 'react'
import cs from 'classnames'
import { Link } from 'react-router-dom'

import styles from './CollectionsList.module.scss'

const CollectionsList = ({ resourceCollections, selectedCollection }) => {
  return (
    <div className={styles.CollectionsList} data-testid="collections-list">
      <div className={styles.section}>
        <ListItem label="All Resources" selected={!selectedCollection} />
      </div>

      <div className={styles.section}>
        <div className={styles.header}>Resource Collections</div>
        {resourceCollections &&
          resourceCollections.map((collection) => (
            <ListItem
              label={collection.name}
              selected={
                selectedCollection && selectedCollection.id === collection.id
              }
              collectionId={collection.id}
              key={collection.id}
            />
          ))}
      </div>
    </div>
  )
}

const ListItem = ({ label, selected, collectionId }) => (
  <Link
    className={cs(styles.ListItem, selected && styles.selected)}
    to={collectionId ? `/resources/${collectionId}` : `/resources/`}
  >
    {label}
  </Link>
)

export default CollectionsList

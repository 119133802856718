import { useSelector } from 'react-redux'
import { addDays } from 'date-fns'

import Loader from '@comp/Loader/Loader'

import DayColumn from './DayColumn'

import {
  useGetClientMealsQuery,
  useGetClientTargetsQuery,
  useGetClientTrackersQuery,
} from '@api/nutritionistApiSlice'
import { useGetFoodlogTotalsQuery } from '@api/analyticsApiSlice'

import {
  selectCurrentClient,
  selectWeekStart,
  selectWeekEnd,
} from '@state/clientSlice'

import { hashByProperty, hashByPropertyAsArray } from '@utils'
import { format, dateFormat, getDayIndex } from '@utils/date'
import {
  sortMealsByDatetime,
  formatMealFromApi,
  formatTargetsProfile,
} from '@utils/meals'

import styles from './MealsGrid.module.scss'

const dayIndices = [0, 1, 2, 3, 4, 5, 6]

const MealsGrid = () => {
  const client = useSelector(selectCurrentClient)
  const weekStart = useSelector(selectWeekStart)
  const weekEnd = useSelector(selectWeekEnd)

  const { data: meals } = useGetClientMealsQuery(
    {
      clientId: client?.id,
      start: format(weekStart, dateFormat),
      end: format(weekEnd, dateFormat),
    },
    { skip: !client }
  )
  const { data: foodlogTotals } = useGetFoodlogTotalsQuery(
    {
      clientId: client?.id,
      start: format(weekStart, dateFormat),
      end: format(weekEnd, dateFormat),
    },
    { skip: !client }
  )
  const { data: trackers } = useGetClientTrackersQuery(
    {
      clientId: client?.id,
      start: format(weekStart, dateFormat),
      end: format(weekEnd, dateFormat),
    },
    { skip: !client }
  )
  const { data: targetProfile } = useGetClientTargetsQuery(
    { clientId: client?.id },
    { skip: !client }
  )

  const targets = formatTargetsProfile(targetProfile)

  if (!meals || !trackers || !foodlogTotals || !targets) return <Loader />

  let mealsByDay = dayIndices.map(() => [])
  meals.forEach((meal) => {
    const { datetime, timezone } = meal
    const dayIndex = getDayIndex(new Date(datetime), timezone)
    mealsByDay[dayIndex].push(formatMealFromApi(meal))
  })
  mealsByDay = mealsByDay.map((meals) => sortMealsByDatetime(meals))

  const hashByDayIndex = (array, hashAsArray) => {
    const mappedByDayIndex = array.map((el) => ({
      ...el,
      dayIndex: getDayIndex(el.date, el.timezone),
    }))
    return hashAsArray
      ? hashByPropertyAsArray(mappedByDayIndex, 'dayIndex')
      : hashByProperty(mappedByDayIndex, 'dayIndex')
  }

  const movements = hashByDayIndex(trackers.movement, true)
  const waters = hashByDayIndex(trackers.water)
  const sleeps = hashByDayIndex(trackers.sleep)
  const weights = hashByDayIndex(trackers.weight)
  const notes = hashByDayIndex(trackers.note)

  return (
    <div className={styles.MealsGrid}>
      {mealsByDay.map((meals, index) => {
        return (
          <DayColumn
            date={addDays(weekStart, index)}
            meals={meals}
            totals={foodlogTotals.totals[index]}
            targets={targets}
            movements={movements[index]}
            water={waters[index]}
            sleep={sleeps[index]}
            weight={weights[index]}
            note={notes[index]}
            index={index}
            key={index}
          />
        )
      })}
    </div>
  )
}

export default MealsGrid

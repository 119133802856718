import { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cs from 'classnames'
import { useLocation } from 'react-router-dom'
import { push } from 'redux-first-history'

import Popup from '@comp/Popup/Popup'

import { useGetClientsQuery } from '@api/nutritionistApiSlice'

import { selectCurrentClient, clientSelected } from '@state/clientSlice'

import { getInitials, getName } from '@utils/user'

import icon_expand from '@assets/icon_expand.svg'

import styles from './ClientSearch.module.scss'

const ClientSearch = () => {
  const dispatch = useDispatch()
  const client = useSelector(selectCurrentClient)
  const [showSearch, set_showSearch] = useState(false)
  const { pathname } = useLocation()

  const { data: clients, isLoading: isLoadingClients } = useGetClientsQuery()

  const matchId = (id1, id2) => id1 && id2 && id1.toString() === id2.toString()

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === '/') {
        set_showSearch(true)
        e.preventDefault()
      }
    }
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  useEffect(() => {
    if (isLoadingClients) return
    const useTester = false
    if (pathname === '/client') {
      const nextClient =
        process.env.REACT_APP_ENV === 'development' && useTester
          ? clients.find(({ email }) => email.includes('engineer'))
          : clients[0]
      dispatch(push(`/client/${nextClient.id}/foodlog`))
    } else {
      const clientId = pathname.split('/')[2]
      const nextClient = clients.find(({ id }) => matchId(id, clientId))
      if (!nextClient) dispatch(push('/client'))
      else if (!matchId(nextClient.id, client?.id))
        dispatch(clientSelected(nextClient))
    }
  }, [isLoadingClients, pathname, client?.id, clients, dispatch])

  if (!client) return null

  const toggleSearch = () => set_showSearch(!showSearch)

  return (
    <div className={styles.ClientSearch}>
      <button
        className={styles.dropdown}
        onClick={toggleSearch}
        data-testid="client-search-dropdown-button"
      >
        {getName(client)}
        <img src={icon_expand} alt="expand client search" />
      </button>

      {showSearch && (
        <SearchMenu clients={clients} onClose={() => set_showSearch(false)} />
      )}
    </div>
  )
}

const SearchMenu = ({ clients, onClose }) => {
  const scrollbarMargin = 20
  const clientElHeight = 54

  const dispatch = useDispatch()
  const [filter, setFilter] = useState('')
  const [sbHeight, setSbHeight] = useState(null)
  const [sbTop, setSbTop] = useState(scrollbarMargin / 2)
  const [clientIndex, setClientIndex] = useState(0)
  const [mouseOver, setMouseOver] = useState(true)
  const filterRef = useRef(null)
  const clientsRef = useRef(null)

  const filtered = filter
    ? clients.filter(
        (client) =>
          client.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          client.lastName.toLowerCase().includes(filter.toLowerCase())
      )
    : clients

  const totalHeight = filtered.length * clientElHeight

  const updateScrollBar = useCallback(
    (windowHeight) => {
      if (windowHeight > totalHeight - clientElHeight || windowHeight < 0) {
        setSbHeight(0)
      } else {
        setSbHeight(
          ((windowHeight + scrollbarMargin) / totalHeight) * windowHeight
        )
      }
    },
    [totalHeight]
  )

  useEffect(() => {
    if (!clientsRef.current) return
    const windowHeight = clientsRef.current.offsetHeight - scrollbarMargin
    updateScrollBar(windowHeight)
  }, [totalHeight, updateScrollBar])

  useEffect(() => {
    if (filterRef.current) filterRef.current.focus()
  }, [])

  const handleFilter = (e) => {
    setFilter(e.target.value)
    const windowHeight = clientsRef.current.offsetHeight - scrollbarMargin
    updateScrollBar(windowHeight)
    setSbTop(scrollbarMargin / 2)
    setClientIndex(0)
  }

  const handleScroll = (e) => {
    const windowHeight = e.target.offsetHeight - scrollbarMargin
    setSbTop(
      (e.target.scrollTop / totalHeight) * windowHeight + scrollbarMargin / 2
    )
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') onClose()
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      handleChangeIndex(Math.max(clientIndex - 1, 0))
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      handleChangeIndex(Math.min(clientIndex + 1, filtered.length - 1))
    }
    if (e.key === 'Enter') selectClient(filtered[clientIndex])
    if (e.key === '/') e.preventDefault()
  }

  const handleChangeIndex = (index) => {
    clientsRef.current.children[index]?.scrollIntoView({ block: 'nearest' })
    setClientIndex(index)
    setMouseOver(false)
  }

  const selectClient = (client) => {
    if (!client) return
    dispatch(push(`/client/${client.id}/foodlog`))
    onClose()
  }

  return (
    <Popup
      className={styles.searchMenu}
      onClose={onClose}
      style={{ maxHeight: window.innerHeight - 100 }}
    >
      <div className={styles.filterContainer}>
        <input
          className={styles.filter}
          id="filter"
          placeholder="Filter by name..."
          value={filter}
          onChange={handleFilter}
          autoComplete="off"
          ref={filterRef}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className={styles.clients} onScroll={handleScroll} ref={clientsRef}>
        {filtered.map((client, index) => (
          <button
            className={cs(
              styles.client,
              index === clientIndex && styles.selected
            )}
            style={{ height: clientElHeight }}
            key={client.firstName + client.lastName}
            onClick={() => selectClient(client)}
            onMouseMove={() => setMouseOver(true)}
            onMouseEnter={() => mouseOver && setClientIndex(index)}
          >
            <div className={styles.avatar}>{getInitials(client)}</div>
            <div className={styles.name}>{getName(client)}</div>
          </button>
        ))}
      </div>
      <div className={styles.scrollOverlay}>
        <div
          className={styles.scrollbar}
          style={{
            height: sbHeight,
            top: sbTop,
          }}
        />
      </div>
    </Popup>
  )
}

export default ClientSearch

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiHost } from '@api'

import { sortBy } from '@utils'

export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['ClientGroup'],
  endpoints: (builder) => ({
    getClientGroups: builder.query({
      query: () => ({
        url: `/client-groups`,
        method: 'GET',
      }),
      transformResponse: (response) => sortBy(response, 'name'),
      providesTags: ['ClientGroup'],
    }),
    createClientGroup: builder.mutation({
      query: ({ name }) => ({
        url: `/client-groups`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['ClientGroup'],
    }),
    updateClientGroup: builder.mutation({
      query: ({ id, name, clients }) => ({
        url: `/client-groups/${id}`,
        method: 'PUT',
        body: { name, clients },
      }),
      invalidatesTags: ['ClientGroup'],
    }),
    deleteClientGroup: builder.mutation({
      query: ({ id }) => ({
        url: `/client-groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ClientGroup'],
    }),
    assignClientGroupResources: builder.mutation({
      query: ({ groupId, resources, resourceCollections }) => ({
        url: `/client-groups/${groupId}/resources`,
        method: 'POST',
        body: { resources, resourceCollections },
      }),
    }),
  }),
})

export const {
  useGetClientGroupsQuery,
  useCreateClientGroupMutation,
  useUpdateClientGroupMutation,
  useDeleteClientGroupMutation,
  useAssignClientGroupResourcesMutation,
} = groupsApi

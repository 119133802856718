import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button from '@comp/Button/Button'
import { Spinner } from '@comp/Loader/Loader'
import { UpdateGroupDialogue } from './GroupDialogues'
import ClientSelectorModal from './ClientSelectorModal'
import MessageModal from '@pages/Chat/ScheduleModalMass'
import ResourcesModal from '@pages/Profile/Resources/ResourcesModal'

import { useGetClientsQuery } from '@api/nutritionistApiSlice'
import {
  useUpdateClientGroupMutation,
  useAssignClientGroupResourcesMutation,
} from '@api/groupsApiSlice'

import icon_pencil from '@assets/icon_pencil_grey.svg'
import icon_search from '@assets/icon_search_grey.svg'
import icon_close from '@assets/icon_close_grey.svg'
import icon_arrow_dropdown from '@assets/icon_arrow_dropdown_grey.svg'
import icon_open from '@assets/icon_open_gray.svg'

import { sortBy } from '@utils'
import { getName } from '@utils/user'
import { formatRelativeToday } from '@utils/date'

import styles from './Group.module.scss'

const Group = ({ selectedGroup }) => {
  const [showUpdateDialogue, set_showUpdateDialogue] = useState()
  const [searchValue, set_searchValue] = useState('')
  const [sortKey, set_sortKey] = useState('name')
  const [sortDesc, set_sortDesc] = useState(false)
  const [showClientsModal, set_showClientsModal] = useState()
  const [showResourcesModal, set_showResourcesModal] = useState()
  const [showMessageModal, set_showMessageModal] = useState()

  const { data: allClients } = useGetClientsQuery()
  const [updateGroup] = useUpdateClientGroupMutation()
  const [saveGroupResources] = useAssignClientGroupResourcesMutation()

  useEffect(() => {
    set_searchValue('')
  }, [selectedGroup])

  let clients = selectedGroup ? selectedGroup.clients : allClients

  if (!clients)
    return (
      <div className={styles.emptyContainer}>
        <Spinner color="#88939d" />
      </div>
    )

  clients = clients
    .map((client) => ({
      ...client,
      name: getName(client),
      lastActive: client.lastActive ? new Date(client.lastActive) : null,
      createdAt: new Date(client.createdAt),
    }))
    .filter(({ name }) => name.includes(searchValue))

  const sortedClients = sortBy(clients, sortKey, sortDesc)

  const sortByName = (clients) =>
    [...clients].sort((a, b) => (getName(a) > getName(b) ? 1 : -1))

  const saveClients = async (selectedClients) => {
    await updateGroup({
      ...selectedGroup,
      clients: selectedClients.map(({ id }) => id),
    })
    set_showClientsModal(false)
  }

  const saveResources = async ({ selectedResources, selectedCollections }) => {
    const res = await saveGroupResources({
      groupId: selectedGroup.id,
      resources: selectedResources.map(({ id }) => id),
      resourceCollections: selectedCollections.map(({ id }) => id),
    })
    if (res.error) alert(res.error.message)
    else toast.success('Resources have been assigned')
    set_showResourcesModal(false)
  }

  const handleSort = (key) => {
    if (key === sortKey) set_sortDesc(!sortDesc)
    else {
      set_sortKey(key)
      set_sortDesc(tableColumns.find((c) => c.key === key).sortDesc)
    }
  }

  const tableColumns = [
    { key: 'name', label: 'Name', sortDesc: false },
    { key: 'lastActive', label: 'Last Online', sortDesc: true },
    { key: 'createdAt', label: 'Joined', sortDesc: true },
    { key: 'email', label: 'Email', sortDesc: false },
    { key: 'open', label: '', sortDesc: null },
  ]

  return (
    <div className={styles.Group} data-testid="group">
      <div className={styles.header}>
        <div className={styles.name} data-testid="group-name">
          {selectedGroup ? selectedGroup.name : 'All Clients'}
        </div>
        {selectedGroup && (
          <img
            className={styles.edit}
            src={icon_pencil}
            onClick={() => set_showUpdateDialogue(true)}
            alt="edit group"
          />
        )}

        <div className={styles.actions}>
          {selectedGroup ? (
            <Button
              full
              color="white"
              border
              onClick={() => set_showClientsModal(true)}
              alt="manage members"
            >
              Manage Members
            </Button>
          ) : (
            <div />
          )}
          {selectedGroup ? (
            <Button
              full
              color="white"
              border
              onClick={() => set_showResourcesModal(true)}
              alt="Assign Resources"
            >
              Assign Resources
            </Button>
          ) : (
            <div />
          )}
          <Button
            full
            color="white"
            border
            onClick={() => set_showMessageModal(true)}
          >
            Send Message
          </Button>
          <div className={styles.searchContainer}>
            <input
              className={styles.search}
              value={searchValue}
              onChange={(e) => set_searchValue(e.target.value)}
              placeholder="Search members"
            />
            <div className={styles.searchIcon}>
              {searchValue ? (
                <img
                  src={icon_close}
                  onClick={() => set_searchValue('')}
                  alt="clear search"
                />
              ) : (
                <img src={icon_search} alt="search" />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.clientsTable}>
        <div className={styles.tableHeader}>
          {tableColumns.map(({ key, label, sortDesc }) => {
            return (
              <div
                className={styles.col}
                onClick={() => handleSort(key)}
                key={key}
              >
                <span>
                  {label}
                  {key === sortKey && sortDesc !== null && (
                    <img
                      className={!sortDesc && styles.invert}
                      src={icon_arrow_dropdown}
                      alt="sort"
                    />
                  )}
                </span>
              </div>
            )
          })}
        </div>

        <div className={styles.tableBody}>
          {sortedClients.map((client) => (
            <ClientRow client={client} key={client.id} />
          ))}
        </div>
      </div>

      {showUpdateDialogue && (
        <UpdateGroupDialogue
          group={selectedGroup}
          onClose={() => set_showUpdateDialogue(false)}
        />
      )}

      {showClientsModal && (
        <ClientSelectorModal
          allClients={allClients}
          currentClients={sortByName(selectedGroup.clients)}
          saveSelectedClients={saveClients}
        />
      )}

      {showResourcesModal && (
        <ResourcesModal
          assignedResources={[]}
          assignedCollections={[]}
          onClose={saveResources}
        />
      )}

      {showMessageModal && (
        <MessageModal
          onClose={() => set_showMessageModal(false)}
          initialAllClients={!selectedGroup}
          initialGroups={selectedGroup ? [selectedGroup] : []}
        />
      )}
    </div>
  )
}

const ClientRow = ({ client }) => {
  const displayDate = (date) => {
    if (!date) return '-'
    return formatRelativeToday(date)
  }

  return (
    <div className={styles.ClientRow} data-testid="group-client-row">
      <div className={styles.col}>{client.name}</div>
      <div className={styles.col}>{displayDate(client.lastActive)}</div>
      <div className={styles.col}>{displayDate(client.createdAt)}</div>
      <div className={styles.col}>{client.email}</div>
      <div className={styles.open}>
        <NavLink to={`/client/${client.id}`} target="_blank">
          <img src={icon_open} alt="open client profile" />
        </NavLink>
      </div>
    </div>
  )
}

export default Group

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiHost } from '@api'

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getFoodlogTotals: builder.query({
      query: ({ clientId, start, end }) => ({
        url: `/analytics/totals?client=${clientId}&start=${start}&end=${end}`,
        method: 'GET',
      }),
    }),
    getFoodlogAverages: builder.query({
      query: ({ clientId, start, end }) => ({
        url: `/analytics/averages?client=${clientId}&start=${start}&end=${end}`,
        method: 'GET',
      }),
    }),
    getFoodlogStats: builder.query({
      query: ({ clientId, start, end }) => ({
        url: `/analytics/stats?client=${clientId}&start=${start}&end=${end}`,
        method: 'GET',
      }),
    }),
    getWeights: builder.query({
      query: ({ clientId, start, end }) => ({
        url: `/analytics/weights?client=${clientId}&start=${start}&end=${end}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetFoodlogTotalsQuery,
  useGetFoodlogAveragesQuery,
  useGetFoodlogStatsQuery,
  useGetWeightsQuery,
} = analyticsApi

import { useState, useEffect } from 'react'
import { StreamChat } from 'stream-chat'

export const chatClient = StreamChat.getInstance(
  process.env.REACT_APP_STREAM_API_KEY
)

export const useChatClient = (user) => {
  const [chatIsReady, set_chatIsReady] = useState(false)
  const [chatUser, set_chatUser] = useState()

  useEffect(() => {
    if (!user) return

    const setupClient = async () => {
      const userName = `${user.firstName} ${user.lastName}`
      try {
        const response = await chatClient.connectUser(
          {
            id: user.id.toString(),
            name: userName,
          },
          user.chatToken
        )
        set_chatIsReady(true)
        set_chatUser(response.me)
      } catch (error) {
        if (error instanceof Error) {
          console.error(
            `An error occurred while connecting the user: ${error.message}`
          )
        }
      }
    }

    if (!chatClient.userID) {
      setupClient()
    }
  }, [user])

  return {
    chatIsReady,
    chatUser,
  }
}

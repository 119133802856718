import { Form, FormTitle } from '@comp/Form/Form'

import iconOpn from '@assets/icon-opn-large.svg'

import styles from './Account.module.scss'

const AccountError = () => {
  return (
    <div className={styles.Account}>
      <img className={styles.icon} src={iconOpn} alt="onpoint nutrition logo" />
      <Form className={styles.form}>
        <FormTitle>Uh oh!</FormTitle>
        <div className={styles.details}>
          There was an issue with your account. Please contact support so that
          we can help you adress this issue.
        </div>
      </Form>
    </div>
  )
}

export default AccountError

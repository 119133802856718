import { isRejectedWithValue } from '@reduxjs/toolkit'

import { setLocally } from '@utils/storage'

import { signedOut } from '@state/authSlice'

export const rtkQueryErrorHandler = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status = action.payload?.status
    if (status === 401) {
      setLocally('userToken', null)
      api.dispatch(signedOut({ unauthorized: true }))
    }
    if (status === 403) {
      throw new Error('Oops. Something went wrong. Please contact support.')
    }
  }

  return next(action)
}

import WeekSelector from './WeekSelector'
import MealsGrid from './MealsGrid'

import styles from './Foodlog.module.scss'

const Foodlog = () => {
  return (
    <div className={styles.Foodlog} data-testid="client-page-foodlog">
      <WeekSelector />
      <MealsGrid />
    </div>
  )
}

export default Foodlog

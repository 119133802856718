import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Attachment,
  ChannelList,
  Channel,
  MessageInput,
  MessageList,
  useChatContext,
} from 'stream-chat-react'
import 'stream-chat-react/dist/css/index.css'
import { isAfter } from 'date-fns'

import ChannelPreview from './ChannelPreview'
import ScheduledMessagesList from './ScheduledMessagesList'
import ScheduleModal from './ScheduleModal'
import MassMessages from './MassMessages'
import HeaderButton from '@comp/HeaderButton/HeaderButton'

import { useGetScheduledMessagesQuery } from '@api/messagesApiSlice'
import { useGetClientsQuery } from '@api/nutritionistApiSlice'

import { useAuth } from '@utils/hooks'
import { chatClient } from '@utils/chat'

import styles from './Chat.module.scss'
import './Chat.global.scss'

import icon_opn from '@assets/icon_opn.svg'
import icon_groups from '@assets/icon_groups.svg'

const ChatPage = () => {
  const location = useLocation()
  const [initialClient, set_initialClient] = useState(location.state?.client)

  useGetClientsQuery()

  const { user } = useAuth()
  const [search, set_search] = useState('')
  const [showMassMessages, set_showMassMessages] = useState()

  const { channel, setActiveChannel } = useChatContext()

  let members = { $in: [`${user.id}`] }
  if (initialClient) members = { $eq: [`${user.id}`, `${initialClient.id}`] }

  const filters = {
    frozen: false,
    members,
  }
  const sort = { last_message_at: -1 }
  const options = { limit: 30 }

  const { data: messages, refetch: refetchScheduledMessages } =
    useGetScheduledMessagesQuery()

  const clientId = Number(channel?.data.created_by.id)

  const activeMessages = messages
    ? messages.filter(
        ({ direct, datetime, clients }) =>
          direct &&
          isAfter(new Date(datetime), new Date()) &&
          clients[0]?.id === clientId
      )
    : []

  useEffect(() => {
    return () => {
      setActiveChannel(null)
    }
  }, [setActiveChannel])

  useEffect(() => {
    chatClient.on('message.new', refetchScheduledMessages)
  }, [refetchScheduledMessages])

  return (
    <div className={styles.Chat} data-testid="chat-page">
      <ChannelList
        filters={filters}
        sort={sort}
        options={options}
        channelRenderFilterFn={(channels) =>
          channels.filter(({ data }) =>
            data.name.toLowerCase().includes(search.toLowerCase())
          )
        }
        Preview={ChannelPreview}
        showChannelSearch
        ChannelSearch={ChannelSearch}
        additionalChannelSearchProps={{
          search,
          set_search,
          set_showMassMessages,
          showAll: initialClient ? () => set_initialClient(null) : null,
        }}
        EmptyStateIndicator={EmptyStateIndicator}
        customActiveChannel={initialClient ? initialClient.channelId : null}
        setActiveChannelOnMount={false}
        Paginator={Paginator}
      />
      {channel ? (
        <Channel Attachment={CustomAttachment}>
          <ChannelHeader activeMessages={activeMessages} />
          <MessageList messageActions={['edit', 'delete', 'react', 'quote']} />
          <MessageInput grow />
        </Channel>
      ) : showMassMessages ? (
        <MassMessages />
      ) : (
        <div className={styles.blank}>
          <img src={icon_opn} alt="opn logo" />
        </div>
      )}
    </div>
  )
}

const ChannelSearch = ({
  search,
  set_search,
  set_showMassMessages,
  showAll,
}) => {
  const { setActiveChannel } = useChatContext()

  if (showAll)
    return (
      <div className={styles.searchContainer}>
        <div className={styles.showAll} onClick={showAll}>
          Show all clients
        </div>
      </div>
    )

  return (
    <div className={styles.searchContainer}>
      <input
        className={styles.search}
        value={search}
        onChange={(e) => set_search(e.target.value)}
        placeholder="Search"
      />

      <button
        className={styles.massMessages}
        onClick={() => {
          setActiveChannel(null)
          set_showMassMessages(true)
        }}
      >
        <img src={icon_groups} alt="mass message" />
      </button>
    </div>
  )
}

const EmptyStateIndicator = () => (
  <div className={styles.empty}>No results found</div>
)

const ChannelHeader = ({ activeMessages }) => {
  const navigate = useNavigate()
  const [showScheduledMessages, set_showScheduledMessages] = useState()
  const [showScheduleModal, set_showScheduleModal] = useState()

  const { channel } = useChatContext()
  const clientId = channel.data.created_by.id

  const numActiveMessages = activeMessages.length

  return (
    <div className={styles.channelHeader}>
      <div className={styles.name} data-testid="chat-page-client-name">
        {channel.data.name}
      </div>
      <div className={styles.buttons}>
        <HeaderButton onClick={() => set_showScheduledMessages(true)}>
          Scheduled Messages
          {numActiveMessages ? (
            <span className={styles.numActiveMessages}>
              ({numActiveMessages})
            </span>
          ) : null}
          {showScheduledMessages && (
            <ScheduledMessagesList
              messages={activeMessages}
              onClose={() => set_showScheduledMessages(false)}
              openScheduleModal={() => set_showScheduleModal(true)}
              closeOnClickOutside={!showScheduleModal}
            />
          )}
        </HeaderButton>
        <HeaderButton onClick={() => navigate(`/client/${clientId}/foodlog`)}>
          Profile
        </HeaderButton>
      </div>

      {showScheduleModal && (
        <ScheduleModal
          clientId={clientId}
          onClose={() => set_showScheduleModal(false)}
        />
      )}
    </div>
  )
}

const CustomAttachment = (props) => {
  const filtered = props.attachments.filter(
    ({ og_scrape_url }) =>
      og_scrape_url === undefined || !og_scrape_url.includes('nopreview=true')
  )
  return <Attachment {...props} attachments={filtered} />
}

const Paginator = ({ children, hasNextPage, refreshing, loadNextPage }) => {
  useEffect(() => {
    if (hasNextPage && !refreshing) loadNextPage()
  }, [hasNextPage, refreshing, loadNextPage])

  return children
}

export default ChatPage

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiHost } from '@api'

import { sortBy, changeCamelCase } from '@utils'

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Client'],
  endpoints: (builder) => ({
    searchClients: builder.query({
      query: ({ search }) => ({
        url: `/users/?search=${search}`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        changeCamelCase(sortBy(response, 'firstName')).filter(
          ({ role }) => role !== 4
        ),
      providesTags: ['Client'],
    }),
    getNutritionists: builder.query({
      query: () => ({
        url: `/users/nutritionists/`,
        method: 'GET',
      }),
      transformResponse: (response) =>
        changeCamelCase(sortBy(response, 'firstName')),
    }),
    updateClient: builder.mutation({
      query: ({ userId, body }) => ({
        url: `/users/${userId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Client'],
    }),
  }),
})

export const {
  useSearchClientsQuery,
  useGetNutritionistsQuery,
  useUpdateClientMutation,
} = adminApi

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { apiHost } from '@api'

import { sortBy } from '@utils'

export const resourcesApi = createApi({
  reducerPath: 'resourcesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['ResourceCollection', 'UserResource', 'UserResourceCollection'],
  endpoints: (builder) => ({
    getResourceCollections: builder.query({
      query: () => ({
        url: `/resource-collections`,
        method: 'GET',
      }),
      transformResponse: (collections) => sortBy(collections, 'name'),
      providesTags: ['ResourceCollection'],
    }),
    getClientResources: builder.query({
      query: ({ clientId }) => ({
        url: `/user-resources?client=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['UserResource'],
    }),
    setClientResources: builder.mutation({
      query: ({ clientId, resources }) => ({
        url: `/user-resources?client=${clientId}`,
        method: 'PUT',
        body: { resources },
      }),
      invalidatesTags: ['UserResource'],
    }),
    getClientResourceCollections: builder.query({
      query: ({ clientId }) => ({
        url: `/user-resource-collections?client=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['UserResourceCollection'],
    }),
    setClientResourceCollections: builder.mutation({
      query: ({ clientId, resourceCollections }) => ({
        url: `/user-resource-collections?client=${clientId}`,
        method: 'PUT',
        body: { resourceCollections },
      }),
      invalidatesTags: ['UserResourceCollection'],
    }),
  }),
})

export const {
  useGetResourceCollectionsQuery,
  useGetClientResourcesQuery,
  useSetClientResourcesMutation,
  useGetClientResourceCollectionsQuery,
  useSetClientResourceCollectionsMutation,
} = resourcesApi

import React, { useState } from 'react'

import Modal from '@comp/Modal/Modal'
import SelectList, { SelectItem } from '@comp/SelectList/SelectList'
import { FormInputGroup } from '@comp/Form/Form'

import { getName } from '@utils/user'

import icon_close from '@assets/icon_close.svg'

import styles from './ClientSelectorModal.module.scss'

const ClientSelectorModal = ({
  allClients,
  currentClients,
  saveSelectedClients,
}) => {
  const [selectedClients, set_selectedClients] = useState(currentClients)
  const [search, set_search] = useState('')

  const toggleClient = (client) => {
    if (selectedClients.find(({ id }) => client.id === id)) {
      set_selectedClients(selectedClients.filter(({ id }) => client.id !== id))
    } else {
      set_selectedClients([...selectedClients, client])
    }
  }

  const matchesSearch = (client) =>
    getName(client).toLowerCase().includes(search.toLowerCase())

  const onClose = async () => saveSelectedClients(selectedClients)

  return (
    <Modal onClose={onClose} closeOnClickOutside={false}>
      <div className={styles.ClientSelectorModal}>
        <div className={styles.header}>
          Clients
          <button onClick={onClose}>
            <img src={icon_close} alt="close clients modal" />
          </button>
        </div>

        <div className={styles.searchContainer}>
          <FormInputGroup
            className={styles.search}
            value={search}
            onChange={set_search}
            placeholder="Search clients"
          />
        </div>

        <div className={styles.clients}>
          <SelectList className={styles.list} title="Current Members">
            {selectedClients.map((client) => {
              return (
                <SelectItem
                  className={styles.item}
                  selected={selectedClients.find(({ id }) => id === client.id)}
                  onSelect={() => toggleClient(client)}
                  key={client.id}
                >
                  {getName(client)}
                </SelectItem>
              )
            })}
          </SelectList>

          <SelectList className={styles.list} title="Available">
            {allClients.filter(matchesSearch).map((client) => (
              <SelectItem
                className={styles.item}
                selected={selectedClients.find(({ id }) => id === client.id)}
                onSelect={() => toggleClient(client)}
                key={client.id}
              >
                {getName(client)}
              </SelectItem>
            ))}
          </SelectList>
        </div>
      </div>
    </Modal>
  )
}

export default ClientSelectorModal

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedGroup: null,
}

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    groupSelected(state, { payload }) {
      state.selectedGroup = payload
    },
  },
})

export const { groupSelected } = groupSlice.actions

export default groupSlice.reducer

export const groupSelector = (state) => state.group.selectedGroup

import { useRef, useEffect } from 'react'
import * as d3 from 'd3'

import { defaultGroups } from '@utils/meals'
import { round } from '@utils'

import styles from './AveragesChart.module.scss'

const AveragesChart = ({ averages }) => {
  const containerRef = useRef()
  const chartRef = useRef()

  const margin = { top: 20, right: 25, bottom: 20, left: 25 }
  const height = 200 - margin.top - margin.bottom

  useEffect(() => {
    const width = containerRef.current.clientWidth - margin.left - margin.right
    const svg = d3.select(chartRef.current)
    d3.selectAll('svg#averages #plot').remove()

    const data = defaultGroups.map(({ name, abbr, color }, index) => {
      const average = averages[`${name}_avg`]
      const sd = averages[`${name}_stddev`]

      return {
        index,
        name,
        abbr,
        color,
        average,
        sd,
        sd_min: average - sd,
        sd_max: average + sd,
      }
    })

    // set the ranges
    const conversion = d3.scaleLinear().range([0, width])
    const value = d3.scaleLinear().range([height, 0])

    // Scale the range of the history
    conversion.domain(d3.extent(data, ({ index }) => index))
    const minValue = Math.abs(d3.min(data, ({ sd_min }) => sd_min))
    const maxValue = Math.abs(d3.max(data, ({ sd_max }) => sd_max))
    const range = Math.max(minValue, maxValue)
    value.domain([-range, range]).range([height, 0])

    // Add background
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('class', styles.plot)

    const plot = svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('id', 'plot')

    // Add zero line
    plot
      .append('line')
      .attr('x1', -5)
      .attr('y1', value(0))
      .attr('x2', width + 5)
      .attr('y2', value(0))
      .attr('class', styles.zero)

    // Select tooltip
    const tooltip = {
      container: d3.select(`.${styles.tooltip}`),
      average: d3.select('#analytics_averages_tooltip_average'),
      sd: d3.select('#analytics_averages_tooltip_sd'),
    }

    const handleMouseOver = (event, { name, average, sd }) => {
      let averageLabel = ''
      if (average > 0) averageLabel += '+'
      averageLabel += `${round(average, 2)} ${name}`
      if (average === null) averageLabel = 'None'
      const sdLabel = sd === null ? 'None' : `${round(sd, 2)} ${name}`

      const offset = event.x > window.innerWidth - 200 ? 210 : 0
      tooltip.container
        .style('opacity', 1)
        .style('left', `${event.x - offset}px`)
        .style('top', `${event.y}px`)
      tooltip.average.html(averageLabel)
      tooltip.sd.html(sdLabel)
    }

    const handleMouseMove = (event) => {
      const offset = event.x > window.innerWidth - 200 ? 210 : 0
      tooltip.container
        .style('left', `${event.x - offset}px`)
        .style('top', `${event.y}px`)
    }

    const handleMouseLeave = () => {
      tooltip.container
        .style('opacity', 0)
        .style('left', `-1000px`)
        .style('top', `-1000px`)
    }

    // Add standard deviation
    plot
      .selectAll('.sd')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', ({ index }) => conversion(index) - 5)
      .attr('y', ({ sd_max }) => value(sd_max))
      .attr('width', 10)
      .attr('height', ({ sd, sd_min, sd_max }) => value(sd_min) - value(sd_max))
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('fill', ({ color }) => color)
      .attr('class', styles.sd)
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseMove)
      .on('mouseleave', handleMouseLeave)

    // Add average
    plot
      .selectAll('.average')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', styles.average)
      .attr('cx', ({ index }) => conversion(index))
      .attr('cy', ({ average }) => value(average || 0))
      .attr('r', 5)
      .attr('fill', ({ color }) => color)
      .attr('opacity', ({ average }) => (average === null ? 0.3 : 1))
      .on('mouseover', handleMouseOver)
      .on('mouseleave', handleMouseLeave)

    // Add the x Axis
    const xAxis = d3
      .axisBottom(conversion)
      .tickValues(data.map(({ index }) => index))
      .tickFormat((index) => data[index].abbr)

    plot
      .append('g')
      .attr('transform', `translate(0, ${height + 25})`)
      .call(xAxis)
      .selectAll('text')
      .attr('class', styles.tickX)
      .style('text-anchor', 'center')

    plot.selectAll('.domain').remove()
    plot.selectAll('.tick line').remove()
  }, [averages, height, margin.bottom, margin.left, margin.right, margin.top])

  return (
    <div
      className={styles.AveragesChart}
      history-testid="client-page-analytics"
      ref={containerRef}
    >
      <svg
        style={{
          height: 230,
          width: '100%',
          marginRight: '0px',
          marginLeft: '0px',
        }}
        id="averages"
        ref={chartRef}
      />

      <div className={styles.tooltip}>
        <div className={styles.label}>Delta</div>
        <div id="analytics_averages_tooltip_average" />
        <div className={styles.label}>SD</div>
        <div id="analytics_averages_tooltip_sd" />
      </div>
    </div>
  )
}

export default AveragesChart
